import camelCase from 'lodash/camelCase';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import { ifProp } from '../../../helpers/general';
import { fromTheme } from '../../../helpers/theme';
import messages from '../../App.messages';
import DatePicker from '../../atoms/DatePicker/DatePicker';
import Input from '../../atoms/Input/Input';
import Select from '../../atoms/Select/Select';
import Hint from '../Hint/Hint';

const TextWrapper = styled.span`
    display: flex;
    margin-bottom: 1em;
    line-height: ${fromTheme('lineHeight', 'text')};
    align-items: center;
`;

const StyledLabel = styled.label`
    cursor: pointer;
    display: flex;
    flex-direction: column;

    ${ifProp(
        'horizontal',
        css`
            flex-direction: row-reverse;
            justify-content: flex-end;

            ${TextWrapper} {
                margin-left: 21px;
            }
        `
    )};

    ${ifProp(
        'noPointer',
        css`
            cursor: auto;
        `
    )};
`;

const StyledHint = styled(Hint)`
    margin-left: 7px;
`;

const switchInputComponent = type => {
    switch (type) {
        case 'select':
            return Select;
        case 'date':
            return DatePicker;
        default:
            return Input;
    }
};

const FormField = ({
    intl,
    label,
    isLabelHidden,
    hint,
    name,
    placeholder,
    ...inputProps
}) => {
    const InputComponent = switchInputComponent(inputProps.type);

    const input = (
        <InputComponent
            name={name || camelCase(label)}
            placeholder={isLabelHidden && !placeholder ? label : placeholder}
            aria-label={label}
            {...inputProps}
        />
    );

    // If the label is hidden, no need for the wrapper...
    return isLabelHidden ? (
        input
    ) : (
        <StyledLabel
            horizontal={inputProps.type === 'checkbox'}
            noPointer={inputProps.type === 'date'}
        >
            {isLabelHidden || (
                <TextWrapper>
                    {label}{' '}
                    {hint && (
                        <StyledHint
                            label={intl.formatMessage(messages.hintLabel, {
                                label,
                            })}
                        >
                            {hint}
                        </StyledHint>
                    )}
                </TextWrapper>
            )}
            {input}
        </StyledLabel>
    );
};

FormField.propTypes = {
    /** Form field label */
    label: PropTypes.string.isRequired,

    /** Whether to hide the label text */
    isLabelHidden: PropTypes.bool,

    /** A hint for the form field, rendered next to the label (only if label is not hidden) */
    hint: PropTypes.string,

    /** intl library inject */
    intl: intlShape,
};

// compare with lodash's isEqual so it works for the options array too
export default injectIntl(React.memo(FormField, isEqual));
