import memoize from 'lodash/memoize';
import React from 'react';
import { compose, withApollo } from 'react-apollo';
import { getLocaleQuery } from '../../../apollo-client/modules/app';
import {
    createUriMutation,
    getNewSchemaQuery,
    getOneUriQuery,
    GET_ONE_URI_QUERY,
} from '../../../apollo-client/modules/uris';
import SnackbarContext from '../../../context/SnackbarContext';
import {
    getVariablesFromQueryParam,
    hasNewError,
    isQueryParamInProps,
    makeHoC,
} from '../../../helpers/general';
import {
    normaliseUrlParams,
    transformNewUriPayload,
    transformSchema,
} from '../../../helpers/schema';
import { addToArray } from '../../../helpers/state';

class NewLinkContainer extends React.Component {
    static contextType = SnackbarContext;

    state = {
        isCreateDone: false,
        createdLink: null,
        snackbarRemoveFns: [],
    };

    normaliseUrlParams = memoize(normaliseUrlParams('uri'));

    transformSchema = memoize((schema, locale) => {
        let transformedSchema = null,
            categorisedSchema = null;

        if (schema) {
            [transformedSchema, categorisedSchema] = transformSchema(
                schema,
                locale
            );
        }

        return {
            transformedSchema,
            categorisedSchema,
        };
    });

    // do not memoize this - it will be slower, but otherwise
    // the values won't update since it's the same object
    // TODO: find a solution to make this faster without memoization
    transformPayload = (schema, values) =>
        transformNewUriPayload(schema, values);

    fetchCreatedLink = async id => {
        const {
            data: { uri },
        } = await this.props.client.query({
            query: GET_ONE_URI_QUERY,
            variables: { id },
        });

        this.setState({
            createdLink: uri,
        });
    };

    saveNew = values =>
        new Promise((resolve, reject) => {
            const { transformedSchema } = this.transformSchema(
                this.props.getNewSchema.schema,
                this.props.getLocale.locale
            );

            const payload = this.transformPayload(transformedSchema, values);

            this.props
                .createUri({
                    variables: {
                        input: payload,
                    },
                    update: async (_, { data }) => {
                        this.setState({
                            isCreateDone: true,
                        });

                        await this.fetchCreatedLink(data.uri.id);

                        resolve();
                    },
                })
                .catch(err => {
                    if (err.networkError) {
                        if (err.networkError.result) {
                            this.context.add(err.networkError.result.error);
                        } else {
                            this.context.add(
                                'An unknown error occurred. Please check that your link is valid and try again. If the problem persists, please try again a little later or contact adam@oetkerdigital.com.'
                            );
                        }
                    }

                    reject();
                });
        });

    goHome = () => this.props.history.push('/');

    componentDidUpdate = prevProps => {
        if (hasNewError('getNewSchema', this.props, prevProps)) {
            const removeFn = this.context.add(
                'Failed to fetch data. You might be seeing cached data.'
            );

            this.setState(addToArray('snackbarRemoveFns', removeFn));
        }
    };

    componentWillUnmount = () => {
        this.state.snackbarRemoveFns.forEach(removeFn => removeFn());
    };

    render = () => {
        const { categorisedSchema, transformedSchema } = this.transformSchema(
            this.props.getNewSchema.schema,
            this.props.getLocale.locale
        );

        const copyUrl = this.normaliseUrlParams(this.props.getOneUri);

        const { Component } = this.props;

        return (
            <Component
                isLoading={!categorisedSchema}
                categorisedSchema={categorisedSchema}
                transformedSchema={transformedSchema}
                copyUrl={copyUrl}
                doSave={this.saveNew}
                goHome={this.goHome}
                showModal={this.state.isCreateDone}
                createdLink={this.state.createdLink}
            />
        );
    };
}

export default makeHoC(
    compose(
        withApollo,
        getLocaleQuery,
        getNewSchemaQuery,
        createUriMutation,
        getOneUriQuery(
            // Get ID to copy from from search param
            getVariablesFromQueryParam('copy-from'),
            // Skip this query if no copy-from param is present
            isQueryParamInProps('copy-from', false)
        )
    )(NewLinkContainer)
);
