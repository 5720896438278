import camelCase from 'lodash/camelCase';
import React from 'react';

/**
 * Use given style if prop is "true" or a given value
 */
export const ifProp = (prop, value, style) => props => {
    if (typeof style === 'undefined') {
        style = value;
        value = true;
    }

    if (props[prop] === value) {
        // if style is a function, call it with the props
        return typeof style === 'function' ? style(props) : style;
    }

    return undefined;
};

/**
 * For use within `styled(...)` to filter props passed to components that don't know
 * what to do with them (e.g. Link, Icon)
 * @param {Component} Component
 * @param {array} filteredProps
 */
export const filterProps =
    (Component, filteredProps = []) =>
    props => {
        const allowedProps = {};

        for (let prop in props) {
            if (!filteredProps.includes(prop)) {
                allowedProps[prop] = props[prop];
            }
        }

        return <Component {...allowedProps} />;
    };

/**
 * Copy a string to the user's clipboard
 * @see https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
 * @param {string} str
 */
export const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);

    // store previous selection
    const selection = document.getSelection();
    const selected =
        selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : false;

    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    // restore previous selection
    if (selected) {
        selection.removeAllRanges();
        selection.addRange(selected);
    }
};

/**
 * Format a given date in ISO format (YYYY-MM-DD)
 * @param {Date} date
 */
export const formatAsISODate = date =>
    new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split('T')[0];

/**
 * Return a param from a query string (simple version)
 * @param {string} queryString
 * @param {string} param
 */
export const getQueryParam = (queryString, param) => {
    const querySplit = queryString.split(/[?&=]/);

    const paramIndex = querySplit.findIndex(p => p === param);

    return querySplit[paramIndex + 1];
};

/**
 * Camelcase all keys within an object
 */
export const camelCaseKeys = obj =>
    Object.keys(obj).reduce((acc, key) => {
        acc[camelCase(key)] = obj[key];
        return acc;
    }, {});

/**
 * Remove odid param from URL string
 * @param {string} url
 * @returns string
 */
export const removeOdid = url =>
    url.replace(/\?odid=.*$/, '').replace(/&odid=.*$/, '');

/**
 * Create a higher order component from a given component.
 * @param {React.Component} Container
 * @returns {(Component: React.Component) => (props: object) => object}
 */
export const makeHoC = Container => Component => {
    const WrappedComponent = props => {
        return <Container {...props} Component={Component} />;
    };

    if (Component.preserveStatic) {
        Component.preserveStatic.forEach(key => {
            WrappedComponent[key] = Component[key];
        });
    }

    return WrappedComponent;
};

/**
 * Parse a param from the query string and return an object with the variables set
 * @param {string} param
 * @returns {(props: object) => object}
 */
export const getVariablesFromQueryParam = param => props => {
    const paramId =
        props.location && props.location.search
            ? getQueryParam(props.location.search, param)
            : '';

    return { variables: { id: parseInt(paramId) } };
};

/**
 * Get a param from the URL and return an object with the variables set
 * @param {string} param
 * @returns {(props: object) => object}
 */
export const getVariablesFromUrlParam = param => props => {
    return { variables: { id: props.match.params[param] } };
};

/**
 * Check if a given param is (or is not) set within the query param.
 * @param {string} param
 * @param {boolean} shouldHave
 * @returns {boolean}
 */
export const isQueryParamInProps =
    (param, shouldHave = true) =>
    props => {
        const hasParam =
            props.location &&
            props.location.search &&
            !!getQueryParam(props.location.search, param);

        return shouldHave ? hasParam : !hasParam;
    };

/**
 * Check if theres a new error within the props of an object with a given key.
 * @param {string} key
 * @param {object} props
 * @param {object} prevProps
 * @return {boolean}
 */
export const hasNewError = (key, props, prevProps) => {
    return (
        (!!props[key].error && !prevProps[key].error) ||
        (!!props[key].error &&
            !!prevProps[key].error &&
            props[key].error.message !== prevProps[key].error.message)
    );
};

/**
 * Generate the campaign ID (unix timestamp in seconds) from a date string.
 * @param {string} dateString
 * @returns {number}
 */
export const generateCampaignId = dateString => {
    return Math.floor(new Date(dateString).getTime() / 1000);
};
