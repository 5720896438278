import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { color, fromTheme } from '../../../helpers/theme';
import messages from '../../App.messages';
import Logo from '../../atoms/Logo/Logo';

const Wrapper = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: ${fromTheme('fontWeight', 'bold')};
    letter-spacing: 3.5px;
    color: ${color('header', 'contrast')};
`;

const StyledLogo = styled(Logo)`
    margin-right: 14px;
`;

const TextLogo = () => (
    <Wrapper>
        <StyledLogo isSmall />
        <FormattedMessage {...messages.name} />
    </Wrapper>
);

export default TextLogo;
