import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactSelect from 'react-select';
import { ThemeContext } from 'styled-components/macro';
import { color, fromTheme } from '../../../helpers/theme';

const extendTheme = theme => ({
    indicatorSeparator: base => ({
        ...base,
        width: 0,
    }),
    control: (base, state) => ({
        ...base,
        borderColor: state.selectProps.hasError
            ? color('error', 'main')({ theme })
            : color('selectInput', 'border')({ theme }),
        backgroundColor: state.selectProps.hasError
            ? color('error', 'bg')({ theme })
            : base.backgroundColor,
        boxShadow: 'none !important',
        borderWidth: '2px',
        borderRadius: fromTheme('borderRadius', 'main')({ theme }),
        minHeight: 0,
        height: '41px',
        '&:hover': {
            borderColor: color('selectInput', 'border')({ theme }),
        },
        padding: '0 9px 10px 13px',
    }),
    valueContainer: base => ({
        ...base,
        lineHeight: fromTheme('lineHeight', 'text')({ theme }),
        paddingLeft: 0,
        paddingRight: 0,
    }),
    placeholder: base => ({
        ...base,
        color: color('selectInput', 'placeholder')({ theme }),
        fontWeight: fromTheme('fontWeight', 'bold')({ theme }),
    }),
    singleValue: base => ({
        ...base,
        color: color('selectInput', 'placeholder')({ theme }),
        fontWeight: fromTheme('fontWeight', 'bold')({ theme }),
    }),
    dropdownIndicator: base => ({
        ...base,
        color: color('selectInput', 'arrow')({ theme }),
        paddingRight: 0,
        paddingLeft: 0,
    }),
    menu: base => ({
        ...base,
        marginTop: '-3px',
        marginBottom: 0,
        borderRadius: 0,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: color('selectInput', 'menuBorder')({ theme }),
        boxShadow: `-7px 9px 15px ${color(
            'selectInput',
            'menuShadow'
        )({
            theme,
        })}`,
        zIndex: 2,
    }),
    menuList: base => ({
        ...base,
        paddingTop: '16px',
        paddingBottom: '9px',
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor:
            state.isFocused || state.isSelected
                ? color('selectInput', 'optionHoverBg')({ theme })
                : base.backgroundColor,
        color:
            state.isFocused || state.isSelected
                ? color('selectInput', 'optionHover')({ theme })
                : color('text', 'main')({ theme }),
        padding: '0 21px',
        lineHeight: '33px',
        cursor: 'pointer',
    }),
});

const Select = ({
    name,
    value,
    options,
    onChange,
    onBlur,
    isDisabled,
    hasError,
}) => {
    const theme = useContext(ThemeContext);

    return (
        <ReactSelect
            // react-select will not reset if the value is '' (the default)
            // cause `undefined` would be passed from find
            value={options.find(o => o.value === value) || value}
            onChange={option =>
                onChange &&
                onChange({
                    target: {
                        type: 'select',
                        name,
                        value: option.value,
                    },
                })
            }
            onBlur={onBlur}
            options={options}
            isDisabled={isDisabled}
            hasError={hasError}
            styles={extendTheme(theme)}
        />
    );
};

Select.propTypes = {
    /** Input name */
    name: PropTypes.string.isRequired,

    /** Current select value */
    value: PropTypes.any,

    /** Options for the select */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),

    /** Handler for change event */
    onChange: PropTypes.func,

    /** Hadler for blur event  */
    onBlur: PropTypes.func,

    /** Whether the input is disabled */
    isDisabled: PropTypes.bool,

    /** Whether to display an error state */
    hasError: PropTypes.bool,
};

Select.defaultProps = {
    options: [],
    isDisabled: false,
    hasError: false,
};

export default Select;
