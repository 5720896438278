import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { generateCampaignId, removeOdid } from '../../../helpers/general';
import routes from '../../../routes';
import Link from '../../atoms/Link/Link';
import LinkButton from '../../atoms/LinkButton/LinkButton';
import Loading from '../../atoms/Loading/Loading';
import Box from '../../molecules/Box/Box';
import CopyButton from '../../molecules/CopyButton/CopyButton';
import Table from '../../organisms/Table/Table';
import Default from '../../templates/Default/Default';
import SpacedContent from '../../templates/SpacedContent/SpacedContent';
import withHomeContainer from './Home.container';
import messages from './Home.messages';
import Button from '../../atoms/Button/Button';
import { URI_LIST_PAGE_SIZE } from '../../../config';

const StyledTable = styled(Table)`
    margin-top: 48px;
    margin-bottom: 48px;
`;

const StyledButton = styled(Button)`
    margin-left: 16px;
    ${({ isHidden }) => isHidden && 'display: none'};
`;

const ButtonsCentered = styled.div`
    display: flex;
    justify-content: center;
    ${({ isDisabled }) => isDisabled && 'display: none'}
`;

const StyledTextBlock = styled.div`
    font-size: ${({ theme }) => theme.fontSize.title};
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

const StyledSpan = styled.span`
    padding-right: 4px;
`;

const tableColumns = [
    {
        name: 'Campaign ID',
        accessor: row => (
            <Link to={`/link/${row.id}`}>
                {generateCampaignId(row.createdAt)}
            </Link>
        ),
        sortKey: 'created_at',
        rowHeading: true,
    },
    {
        name: 'Campaign Name',
        accessor: 'Campaign Name',
        sortKey: 'parameter_value',
    },
    { name: 'Brand', accessor: 'Brand' },
    { name: 'Channel', accessor: 'Channel' },
    { name: 'Subchannel', accessor: 'Subchannel' },
    {
        name: 'Destination URL',
        accessor: row => (
            <Link as="a" href={removeOdid(row.url)} restrictWidth>
                {removeOdid(row.url)}
            </Link>
        ),
    },
    {
        name: '',
        accessor: row => (
            <React.Fragment>
                <CopyButton copy={row.shortenedUrl || row.url} />{' '}
                <LinkButton
                    to={`${routes.CREATE_LINK.path}?copy-from=${row.id}`}
                    isSmall
                    isGhost
                >
                    Duplicate
                </LinkButton>
            </React.Fragment>
        ),
    },
];

const CenteredBox = ({ headline }) => {
    return (
        <Default isCentered>
            <Box>
                <SpacedContent>
                    <SpacedContent.Icon icon="bigLink" color="accent" />

                    <SpacedContent.Headline>
                        <FormattedMessage {...messages[headline]} />
                    </SpacedContent.Headline>

                    <SpacedContent.Text>
                        <FormattedMessage {...messages.text} />
                    </SpacedContent.Text>

                    <SpacedContent.Button
                        as={LinkButton}
                        to={routes.CREATE_LINK.path}
                    >
                        <FormattedMessage {...messages.btnText} />
                    </SpacedContent.Button>
                </SpacedContent>
            </Box>
        </Default>
    );
};

const Home = ({ loading, data, changePage, goBack, page, ...tableProps }) => {
    if (!data && loading) {
        return (
            <Default isCentered>
                <Loading />
            </Default>
        );
    }

    const isFirstPage = page === 1;
    const hasNoLinksAtAll = !data && isFirstPage;
    const hasNoMorePages = !data && !isFirstPage;
    const hasPages = !hasNoMorePages;
    const isLastPage = data && data.length < URI_LIST_PAGE_SIZE;
    const buttonsDisabled = isFirstPage && isLastPage;
    const isNextPageDisabled = isLastPage || hasNoMorePages;

    if (hasNoLinksAtAll) {
        return <CenteredBox headline="headline" hasNoLinksAtAll={true} />;
    }

    return (
        <Default>
            <LinkButton to={routes.CREATE_LINK.path}>
                <FormattedMessage {...messages.btnText} />
            </LinkButton>

            {hasPages && (
                <StyledTable
                    columns={tableColumns}
                    rows={data}
                    {...tableProps}
                />
            )}
            {hasNoMorePages && (
                <Fragment>
                    <StyledTable
                        columns={tableColumns}
                        rows={[]}
                        {...tableProps}
                    />
                    <StyledTextBlock>
                        <StyledSpan>
                            <FormattedMessage {...messages.endOfList} />
                        </StyledSpan>
                        <FormattedMessage
                            {...messages.headlineForNoMoreLinks}
                        />
                    </StyledTextBlock>
                </Fragment>
            )}

            <ButtonsCentered isDisabled={buttonsDisabled}>
                <Button
                    disabled={isFirstPage}
                    onClick={() => changePage(page - 1)}
                >
                    <FormattedMessage {...messages.prevPage} />
                </Button>
                <StyledButton
                    onClick={() => changePage(page + 1)}
                    isHidden={isNextPageDisabled}
                >
                    <FormattedMessage {...messages.nextPage} />
                </StyledButton>
            </ButtonsCentered>
        </Default>
    );
};

export { Home };

export default withHomeContainer(React.memo(Home));
