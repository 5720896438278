import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import Button from '../../atoms/Button/Button';
import Headline from '../../atoms/Headline/Headline';
import Icon from '../../atoms/Icon/Icon';

const SpacedIcon = styled(Icon)`
    margin-bottom: 27px;
`;

const SpacedHeadline = styled(Headline)`
    margin-bottom: 12px;
`;

const Text = styled.p`
    max-width: 256px;
    /* word-break: break-all; */
`;

const SpacedButton = styled(Button)`
    margin-top: 21px;
`;

const SpacedContent = ({ children }) => (
    <React.Fragment>{children}</React.Fragment>
);

SpacedContent.propTypes = {
    /** Any amount of child elements content */
    children: PropTypes.node.isRequired,
};

SpacedContent.Icon = SpacedIcon;
SpacedContent.Headline = SpacedHeadline;
SpacedContent.Text = Text;
SpacedContent.Button = SpacedButton;

export default SpacedContent;
