import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import Headline from '../../atoms/Headline/Headline';
import LinkButton from '../../atoms/LinkButton/LinkButton';
import BoxBackground from '../../templates/BoxBackground/BoxBackground';
import messages from './NotFound.messages';

const StyledHeadline = styled(Headline)`
    margin-bottom: 12px;
`;

const Text = styled.p`
    max-width: 256px;
    margin-bottom: 21px;
`;

const NotFound = () => (
    <BoxBackground>
        <StyledHeadline>
            <FormattedMessage {...messages.headline} />
        </StyledHeadline>

        <Text>
            <FormattedMessage {...messages.text} />
        </Text>

        <LinkButton to="/">
            <FormattedMessage {...messages.btnText} />
        </LinkButton>
    </BoxBackground>
);

export default NotFound;
