import { BrowserRouter } from 'react-router-dom';

export const DEFAULT_LOCALE = 'en';
export const DEFAULT_THEME = 'light';
export const BASE_FONT_SIZE = 14;
export const MAX_SNACKBAR_MSGS = 2;
export const URI_LIST_PAGE_SIZE = 20;

export const EDEN_SSO = {
    GRANT_TYPE: 'password',
    URL: window.ENV_CONFIG.SSO_URL,
};

export const ADAM_API = {
    URL: window.ENV_CONFIG.API_URL,
};

export const Router = BrowserRouter;
