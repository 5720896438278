import { defineMessages } from 'react-intl';

const messages = defineMessages({
    headline: {
        id: 'showLink.headline',
        defaultMessage: 'showLink.headline',
    },
});

export default messages;
