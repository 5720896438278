import { defineMessages } from 'react-intl';

const messages = defineMessages({
    headline: {
        id: 'error.headline',
        defaultMessage: 'error.headline',
    },

    text: {
        id: 'error.text',
        defaultMessage: 'error.text',
    },

    btnText: {
        id: 'error.btnText',
        defaultMessage: 'error.btnText',
    },
});

export default messages;
