import React, { useMemo } from 'react';
import { compose } from 'react-apollo';
import { getLocaleQuery } from '../../../apollo-client/modules/app';
import {
    getNewSchemaQuery,
    getOneUriQuery,
} from '../../../apollo-client/modules/uris';
import { getVariablesFromUrlParam, makeHoC } from '../../../helpers/general';
import { normaliseUrlParams, transformSchema } from '../../../helpers/schema';
import NotFound from '../NotFound/NotFound';

const ShowLinkContainer = ({
    Component,
    getOneUri,
    getNewSchema,
    getLocale,
}) => {
    const url = useMemo(
        () => normaliseUrlParams('uri')(getOneUri),
        [getOneUri.uri]
    );

    const { categorisedSchema } = useMemo(() => {
        let transformedSchema = null,
            categorisedSchema = null;

        if (getNewSchema.schema) {
            [transformedSchema, categorisedSchema] = transformSchema(
                getNewSchema.schema,
                getLocale.locale
            );
        }

        return { transformedSchema, categorisedSchema };
    }, [getNewSchema.schema, getLocale.locale]);

    if (!getOneUri.loading && !getOneUri.uri) {
        return <NotFound />;
    } else {
        return (
            <Component
                url={url}
                loading={getOneUri.loading}
                categorisedSchema={categorisedSchema}
            />
        );
    }
};

export default makeHoC(
    compose(
        getLocaleQuery,
        getNewSchemaQuery,
        getOneUriQuery(getVariablesFromUrlParam('id'))
    )(ShowLinkContainer)
);
