import camelCase from 'lodash/camelCase';
import { removeOdid } from './general';

/**
 * Transform the values of a preset dependent on the locale.
 *
 * This does:
 * - Change fields of kind "prefilled" to kind "collection"
 * - Add a label to the values array
 * - Localise title, description and category fields
 *
 * @param {object} preset
 * @param {string} locale
 * @returns {object}
 */
export const transformPresetValues = (preset, locale) => {
    // Fields are called titleDe, titleEn, etc...
    const titleField = camelCase(`title_${locale}`);
    const descriptionField = camelCase(`description_${locale}`);
    const nameField = camelCase(`name_${locale}`);

    return {
        // Just take these values as they are
        id: preset.id,
        key: preset.key,
        parentValueId: preset.parentValueId,
        position: preset.position,
        // A "prefilled" preset is nothing other than a collection with one item
        kind: preset.kind === 'prefilled' ? 'collection' : preset.kind,
        // Add a label to all values
        values: preset.values.map(v => ({
            ...v,
            value: v.id,
            label: v.value,
        })),
        // Localise preset title and description
        // If no title is set use the key field
        title: preset[titleField] || preset.key,
        description: preset[descriptionField],
        // Localise category name and description
        category: preset.category
            ? {
                  id: preset.category.id,
                  name: preset.category[nameField],
                  description: preset.category[descriptionField],
              }
            : {},
    };
};

/**
 * Sort two presets first by category, and if those are the same by position.
 *
 * @param {object} a
 * @param {object} b
 * @returns {1|0|-1}
 */
export const sortByCategoryAndPosition = (a, b) =>
    a.category.id === b.category.id
        ? a.position === b.position
            ? 0
            : a.position < b.position
            ? -1
            : 1
        : a.category.id < b.category.id
        ? -1
        : 1;

/**
 * Build an object with accessor methods to get the values and id of the preset that,
 * based on the current form values, should be displayed.
 * @param {object} presetAttributes
 * @returns {object}
 */
export const buildNestedFieldAccessor = presetAttributes => ({
    // Always add the original attributes to the field so we don't lose those
    ...presetAttributes,

    // Add array for conditional values
    conditionalValues: [],

    /**
     * Array of values to display for the field based on current form values
     * @param {object} formValues
     * @returns {array}
     */
    getValues(formValues) {
        const matchingConditionalValueSet =
            this.getConditionalField(formValues);

        // If no set is found, return an empty array
        return matchingConditionalValueSet
            ? matchingConditionalValueSet.values
            : [];
    },

    /**
     * ID of the original field currently supplying the values for the nested field
     * @param {object} formValues
     * @returns {number|null}
     */
    getConditionalFieldId(formValues) {
        const matchingConditionalValueSet =
            this.getConditionalField(formValues);

        // If no set is found, return null
        return matchingConditionalValueSet
            ? matchingConditionalValueSet.id
            : null;
    },

    /**
     * The field that is currently "active" based on the current form values
     * @param {object} formValues
     * @returns {object}
     */
    getConditionalField(formValues) {
        return this.conditionalValues.find(conditionalValueSet =>
            conditionalValueSet.condition(formValues)
        );
    },
});

/**
 * Transform a preset based on the current locale, and recursively add nested fields.
 *
 * @param {array} presets
 * @param {string} locale
 * @returns {(attribute: object) =>  object}
 */
export const transformPreset = (presets, locale) => preset => ({
    // Transform the existing values so they fit our structure
    ...transformPresetValues(preset, locale),

    // Now find all nested fields and process them. We're looping over all field options to see if
    // we have any field that depends on that exact value to be present. Currently, the resulting array
    // will have just one field, since the field dependencies are pretty linear.
    nestedFields: preset.values.reduce((nestedFields, parentOption) => {
        // Find a possible nested field for the value within the complete array of presets
        const nestedField = presets.find(
            f => f.parentValueId === parentOption.id
        );

        if (nestedField !== undefined) {
            // Recursively transform the preset we found, but remove the id and values field from
            // the resulting data, as we need to process these further
            const { id, values, ...presetValues } = transformPreset(
                presets,
                locale
            )(nestedField);

            // Check if we already have created a nested field with the same key of the field we found
            let existingFieldIndex = nestedFields.findIndex(
                field => field.key === nestedField.key
            );

            // If that field does not exist yet, create it
            if (existingFieldIndex < 0) {
                // Build an accessor to be able to always grab the correct values of the field
                nestedFields.push(buildNestedFieldAccessor(presetValues));

                // Update existing field index after push - this cannot be -1 anymore now!
                existingFieldIndex = nestedFields.findIndex(
                    field => field.key === nestedField.key
                );
            } else {
                const mergeConditionalValues = (prevNestedField, mergeInto) => {
                    if (prevNestedField.conditionalValues.length) {
                        const nestedNestedField = mergeInto.find(
                            f => f.key === prevNestedField.key
                        );

                        if (nestedNestedField) {
                            nestedNestedField.conditionalValues.push(
                                ...prevNestedField.conditionalValues
                            );

                            prevNestedField.nestedFields.forEach(f =>
                                mergeConditionalValues(
                                    f,
                                    nestedNestedField.nestedFields
                                )
                            );
                        }
                    }
                };

                presetValues.nestedFields.forEach(f =>
                    mergeConditionalValues(
                        f,
                        nestedFields[existingFieldIndex].nestedFields
                    )
                );
            }

            // Add conditional values for each depending field, which will then be used by the
            // field accessor we added above to determine which possible values the field current has
            nestedFields[existingFieldIndex].conditionalValues.push({
                // We need to save the ID here so we can send it back to the API later
                id,
                // Add the values here, so the field accessor can pick them if the condition is met
                values,
                // Check if the values should be used based on the current values of the form
                condition: formValues =>
                    formValues[preset.key] === parentOption.id,
                // For debugging purposes
                // conditionString: `${preset.key} === ${parentOption.value}`,
            });
        }

        return nestedFields;
    }, []),
});

/**
 * Categorise an array of given fields based on the category information within each field.
 *
 * @param {array} fields
 * @returns {array}
 */
export const categoriseFields = fields => {
    // Start a new arrat of categories that will each have an array of fields
    const categorisedFields = [];

    // We only need to loop over the root level fields, as nested fields automatically get
    // rendered directly after their parents within the form
    fields.forEach(field => {
        let categoryIndex = categorisedFields.findIndex(
            c => c.id === field.category.id
        );

        // If the category of the current field isn't in the array yet, create a new one
        // Since we sorted by category before this will also automatically be sorted correctly
        if (categoryIndex < 0) {
            categorisedFields.push({
                ...field.category,
                fields: [],
            });

            // Grab correct index since we just pushed into the array
            categoryIndex = categorisedFields.length - 1;
        }

        // Either way, push the field into the correct category item
        categorisedFields[categoryIndex].fields.push(field);
    });

    return categorisedFields;
};

/**
 * Transform a given schema based on the current locale.
 *
 * Within the schema for the API is an array of presets that are used to render the form to create a new link.
 * Each preset has the following attributes:
 * - id => this is the preset_id field that the API will expect to get back on create
 * - key => each key signifies one field in the form. it can be present on multiple presets, if they're dependent on a parent field
 * - kind => type of preset, used to determine which kind of form field to render
 * - position => the position within the form, only set for root level fields
 * - parentValueId => if this is set, it means the field is dependent on a specific value being set to a parent field
 * - values => an array of possible values, with an id that corresponds to the parentValueId of a dependent field
 * - titleXX => localisation strings for the title of the field (the label displayed in the form), falls back to the key
 * - descriptionXX => localisation strings for the description of the field (tooltip)
 * - category => an object with information about the category the field belongs to, used to render the form in parts
 *
 * After the transformation, this returns an array with two items:
 * 0. An array of all transformed fields at root level, with conditional fields being nested within their respective
 *    parent fields. This is used to determine default values and the validation rules for the form, as the
 *    categories are irrelevant in those cases.
 * 1. An array of form categories, with their respective fields nested within. This is used to render the form.
 *
 * All nested fields are as of now collections, and will have a getValues method, which can be used while
 * rendering the form to determine which options should be rendered into the field. If a field doesn't have
 * any options (e.g. parent field not filled yet), it is rendered anyways but greyed out.
 *
 * @param {object} schema
 * @param {string} locale
 * @returns {array}
 */
export const transformSchema = (schema, locale) => {
    // We're getting an array of presets from the API that need to be
    // transformed into a structure of fields we can recursively render
    const presets = schema.uri.parametersAttributes;

    // This is what does most of the magic
    const rootLevelFields = presets
        // Just grab the root level presets that are not dependent on any field
        .filter(a => a.parentValueId === null)
        // Transform all presets. This will find nested fields and recursively
        // transform those as well, until all fields are in a tree-like structure
        .map(transformPreset(presets, locale));

    // Sort fields by category, and within category by position, so they render in the correct order
    rootLevelFields.sort(sortByCategoryAndPosition);

    // Categorise fields for easy segmented rendering
    const categorisedFields = categoriseFields(rootLevelFields);

    // Return both the uncategorised and categorised structure, as the first is useful for calculating
    // default values and validating the form, and the latter is used to render the form structure
    return [rootLevelFields, categorisedFields];
};

/**
 * Get an object of default values from a schema (intended to be passed to Formik).
 *
 * The default value for the url field will always be added no matter the schema.
 * If fields need different default values based on their type at some point,
 * this is also the place to do it (e.g. for dates).
 *
 * If a previous Url item is passed in as the third parameter, will attempt to get
 * values from there and if not use the default value.
 *
 * @param {object} schema
 * @param {any} defaultValue
 * @param {object} copyFromItem
 * @returns {object}

 */
export const getDefaultValuesFromSchema = (
    schema,
    defaultValue = '',
    copyFromItem = null
) => {
    const reduceDefaultValues = (acc, field) => {
        let valueFromCopyItem;

        if (copyFromItem) {
            const param = copyFromItem.parameters.find(
                p => p.key === field.key
            );

            if (param) {
                valueFromCopyItem = param.presetValueId || param.value;
            }
        }

        acc[field.key] = valueFromCopyItem || defaultValue;

        return {
            ...acc,
            ...field.nestedFields.reduce(reduceDefaultValues, {}),
        };
    };

    const url =
        copyFromItem && copyFromItem.url
            ? // If copying from an item and the url is set, remove the odid parameter
              removeOdid(copyFromItem.url)
            : defaultValue;

    return schema.reduce(reduceDefaultValues, {
        url,
    });
};

/**
 * Basically reverses the above transformation, generating a payload of the following form:
 * {
        "uri": {
            "url": "",
            "parse": true,
            "parametersAttributes": {
                "0": {
                    "key": "utm_text",
                    "value": ""
                },
                "1": {
                    "key": "utm_collection",
                    "value": ""
                },
                "2": {
                    "key": "utm_date",
                    "value": ""
                },
            }
        }
    }
 * @param {object} values
 */
export const transformNewUriPayload = (schema, { url, ...values }) => {
    const transformedValues = {};
    let valueIndex = 0;

    const transformFieldValue = field => {
        transformedValues[valueIndex] = {
            key: field.key,
            value: values[field.key] || '(not set)',

            // Add the ID of the original preset in the schema
            presetId: field.getConditionalFieldId
                ? field.getConditionalFieldId(values)
                : field.id,
        };

        // Add the preset value ID of the selected value if the field is a collection
        if (field.kind === 'collection') {
            const possibleValues = field.getValues
                ? field.getValues(values)
                : field.values;

            const selectedValue = possibleValues.find(
                v => v.value === values[field.key]
            );

            if (selectedValue) {
                transformedValues[valueIndex].presetValueId =
                    transformedValues[valueIndex].value;
                transformedValues[valueIndex].value = selectedValue.label;
            }
        }

        // Iterate this manually since we're doing this recursively
        valueIndex++;

        field.nestedFields.forEach(transformFieldValue);
    };

    // Recursive transformation go
    schema.forEach(transformFieldValue);

    const payload = {
        uri: {
            url,
            parse: true,
            parametersAttributes: transformedValues,
        },
    };

    return payload;
};

/**
 * Check if all values of a nested schema are valid
 * @param {object} values
 * @param {object} schema
 * @returns {object}
 */
export const validateValues = (values, schema) => {
    const errors = {};

    const validateField = field => {
        // if the field is a conditional field and doesn't have any possible values
        // skip validation since it's not possible to fill the field
        if (field.getValues && !field.getValues(values).length) {
            return;
        }

        if (!values[field.key]) {
            errors[field.key] = `Required field ${field.key}`;
        }

        field.nestedFields.forEach(validateField);
    };

    schema.forEach(validateField);

    if (!values.url) {
        errors.url = 'Required field url';
    }

    return errors;
};

/**
 * Copy Uri parameters to the actual uri object for easy access
 * @param {Uri} uri
 */
export const copyParamsToUri = uri => {
    const params = uri.parameters.reduce((acc, parameter) => {
        acc[parameter.key] = parameter.value;
        return acc;
    }, {});

    return {
        ...uri,
        ...params,
    };
};

/**
 * Will look for a key in a given wrapper object and normalise the URL params within
 * If the wrapper or the object chosen by the key doesn't exist, or if the object is
 * an array without items, it will return null.
 *
 * @param {string} key
 * @returns {(wrapper: object) => null|object}
 */
export const normaliseUrlParams = key => wrapper => {
    if (!wrapper || !wrapper[key]) {
        return null;
    }

    if (Array.isArray(wrapper[key]) && !wrapper[key].length) {
        return null;
    }

    if (Array.isArray(wrapper[key])) {
        return wrapper[key].map(copyParamsToUri);
    } else {
        return copyParamsToUri(wrapper[key]);
    }
};

/**
 * Reset nested fields if their parent values change
 *
 * @param {object} schema
 * @param {object} values
 * @param {object} prevValues
 * @param {(key: string, value: string) => void} resetFn
 * @returns {void}
 */
export const resetNestedFields = (schema, values, prevValues, resetFn) => {
    // For each field check if its value changed and reset nested fields if present
    const walkFields = (field, isParentValueDifferent) => {
        const isValueDifferent = prevValues[field.key] !== values[field.key];

        if (isParentValueDifferent || isValueDifferent) {
            // reset all directly nested fields if the
            field.nestedFields
                .map(nestedField => nestedField.key)
                .forEach(key => resetFn(key, ''));

            // Recursion go!
        }

        field.nestedFields.forEach(f =>
            walkFields(f, isParentValueDifferent || isValueDifferent)
        );
    };

    // This is recursive!
    schema.forEach(f => walkFields(f, false));
};

/**
 * Set fields that have only one possible value.
 *
 * @param {object} schema
 * @param {object} values
 * @param {(key: string, value: string) => void} resetFn
 * @returns {void}
 */
export const setSingleValueFields = (schema, values, resetFn) => {
    const selectSingleValue = f => {
        if (f.kind !== 'collection') {
            return;
        }

        const fieldOptions = f.getValues ? f.getValues(values) : f.values;

        if (
            fieldOptions.length === 1 &&
            values[f.key] !== fieldOptions[0].value
        ) {
            resetFn(f.key, fieldOptions[0].value);
        }

        f.nestedFields.forEach(selectSingleValue);
    };

    schema.forEach(selectSingleValue);
};
