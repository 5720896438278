import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { filterProps, ifProp } from '../../../helpers/general';
import { color, fromTheme } from '../../../helpers/theme';

// Since styled-components does not filter out custom props and Link won't know
// what to do with them and then React will complain, do it manually!
const Link = styled(filterProps(RouterLink, ['restrictWidth']))`
    color: ${color('primary', 'main')};
    font-weight: ${fromTheme('fontWeight', 'bold')};
    text-decoration: none;

    &:hover {
        color: ${color('primary', 'hover')};
    }

    ${ifProp(
        'restrictWidth',
        css`
            display: block;
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        `
    )};
`;

Link.propTypes = {
    restrictWidth: PropTypes.bool,
};

Link.defaultProps = {
    restrictWidth: false,
};

export default Link;
