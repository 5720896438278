import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { color } from '../../../helpers/theme';
// import all svgs as react components and add them to an object
import { ReactComponent as BigCheck } from './assets/big-check.svg';
import { ReactComponent as BigLink } from './assets/big-link.svg';
import { ReactComponent as CalendarArrow } from './assets/calendar-arrow.svg';
import { ReactComponent as Calendar } from './assets/calendar.svg';
import { ReactComponent as ExpandMore } from './assets/expand-more.svg';
import { ReactComponent as MenuLink } from './assets/menu-link.svg';
import { ReactComponent as Question } from './assets/question.svg';

export const iconComponents = {
    bigLink: BigLink,
    bigCheck: BigCheck,
    menuLink: MenuLink,
    expandMore: ExpandMore,
    calendar: Calendar,
    calendarArrow: CalendarArrow,
    question: Question,
};

const StyledSvg = styled.span`
    color: ${props =>
        props.color ? color(props.color)(props) : 'currentColor'};

    transform: rotate(${props => props.rotate}deg);
    transform-origin: center center;
`;

const Icon = ({ icon, ...props }) => (
    <StyledSvg as={iconComponents[icon]} {...props} />
);

Icon.propTypes = {
    /** Icon identifier */
    icon: PropTypes.oneOf(Object.keys(iconComponents)).isRequired,

    /** Icon color */
    color: PropTypes.oneOf(['accent', 'white']),

    /** Icon rotation */
    rotate: PropTypes.number,
};

Icon.defaultProps = {
    rotate: 0,
};

export default Icon;
