import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { EDEN_SSO } from '../../config';

export const GET_USER_QUERY = gql`
    query GetUser {
        loginData {
            accessToken
            refreshToken
            createdAt
            expiresIn
        }
    }
`;

export const getUserQuery = graphql(GET_USER_QUERY, {
    name: 'getUser',
    options: {
        // This never hits the network, we just read the data from the cache
        fetchPolicy: 'cache-only',
    },
});

export const GET_TOKEN_MUTATION = gql`
    mutation GetToken($username: String!, $password: String!) {
        loginData(
            input: {
                username: $username
                password: $password
                grantType: ${EDEN_SSO.GRANT_TYPE}
            }
        )
            @rest(
                type: "LoginData"
                path: "/oauth/token"
                endpoint: "auth"
                method: "POST"
            ) {
            accessToken
            refreshToken
            createdAt
            expiresIn
        }
    }
`;

export const getTokenMutation = graphql(GET_TOKEN_MUTATION, {
    name: 'getToken',
});
