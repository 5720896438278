import baseTheme, { COLORS } from './base';

const theme = {
    ...baseTheme,

    palette: {
        bg: COLORS.WHITE,

        text: {
            main: COLORS.GREY_8,
            secondary: COLORS.BLUE,
        },

        primary: {
            main: COLORS.ACCENT_BLUE,
            hover: COLORS.ACCENT_BLUE_LIGHT,
            contrast: COLORS.WHITE,
        },

        accent: {
            main: COLORS.ACCENT_CYAN,
            contrast: COLORS.WHITE,
        },

        accent2: {
            main: COLORS.ACCENT_ORANGE,
            contrast: COLORS.WHITE,
        },

        error: {
            main: COLORS.ERROR,
            bg: 'rgba(235, 86, 104, 0.13)',
            contrast: COLORS.WHITE,
        },

        info: {
            main: '#25A1D4',
            contrast: COLORS.WHITE,
        },

        mainInput: {
            border: COLORS.GREY_5,
            background: COLORS.WHITE,
            placeholder: COLORS.GREY_5,
            color: COLORS.BLACK,
        },

        greyInput: {
            border: COLORS.GREY_3,
            background: 'rgba(243, 243, 243, 0.68)',
            placeholder: COLORS.GREY_9,
        },

        checkboxInput: {
            border: COLORS.GREY_7,
        },

        selectInput: {
            border: COLORS.GREY_10,
            placeholder: COLORS.GREY_9,
            arrow: COLORS.GREY_7,
            optionHover: COLORS.GREY_9,
            optionHoverBg: 'rgba(151, 151, 151, 0.1)',
            menuBorder: COLORS.GREY_5,
            menuShadow: 'rgba(201, 201, 201, 0.12)',
        },

        dateInput: {
            calendarNavBg: '#f8f8f8',
            calendarNavBorder: '#dcdcdc',
            calendarNavColor: COLORS.GREY_8,
            calendarBodyBg: COLORS.WHITE,
            calendarBodyBorder: '#dcdcdc',
            calendarBodyColor: COLORS.GREY_8,
        },

        box: {
            background: COLORS.WHITE,
            border: COLORS.GREY_4,
            shadow: 'rgba(177, 177, 177, 0.08)',
        },

        header: {
            main: COLORS.BLUE_LIGHT,
            contrast: COLORS.WHITE,
            border: '#405d95',
        },

        nav: {
            main: COLORS.BLUE_DARK,
            contrast: '#5b6c8d',
            active: COLORS.WHITE,
        },

        modalBackdrop: 'rgba(53, 73, 115, 0.7)',

        table: {
            text: COLORS.GREY_7,
            border: '#d5d5d5',
            header: COLORS.GREY_8,
            headerHighlighted: COLORS.GREY_9,
        },

        tooltip: {
            bg: COLORS.WHITE,
            border: '#dcdcdc',
            shadow: 'rgba(213, 213, 213, 0.08)',
            color: COLORS.GREY_9,
        },
    },
};

export default theme;
