import { Formik } from 'formik';
import memoize from 'lodash/memoize';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { generateCampaignId } from '../../../helpers/general';
import {
    getDefaultValuesFromSchema,
    validateValues,
} from '../../../helpers/schema';
import Headline from '../../atoms/Headline/Headline';
import Loading from '../../atoms/Loading/Loading';
import CopyButton from '../../molecules/CopyButton/CopyButton';
import NewLinkForm from '../../organisms/NewLinkForm/NewLinkForm';
import Default from '../../templates/Default/Default';
import SpacedContent from '../../templates/SpacedContent/SpacedContent';
import withNewLinkContainer from './NewLink.container';
import messages from './NewLink.messages';

const StyledHeadline = styled(Headline)`
    margin: 53px 0 12px 128px;
`;

const Text = styled.p`
    margin-bottom: 41px;
    margin-left: 128px;
`;

class NewLink extends React.PureComponent {
    getInitialValues = memoize((schema, copyUrl) => {
        if (copyUrl) {
            return getDefaultValuesFromSchema(schema, '', copyUrl);
        }

        return getDefaultValuesFromSchema(schema);
    });

    validate = values => validateValues(values, this.props.transformedSchema);

    handleSubmit = (values, actions) => {
        this.props.doSave(values).catch(() => {
            actions.setSubmitting(false);
        });
    };

    onModalClose = () => {
        this.props.goHome();
    };

    render = () => {
        if (this.props.isLoading) {
            return (
                <Default isCentered>
                    <Loading />
                </Default>
            );
        }

        if (this.props.showModal) {
            return (
                <Default
                    hasNoPadding
                    isModalOpen
                    onModalClose={this.onModalClose}
                >
                    <Default.ModalContent>
                        {this.props.createdLink ? (
                            <SpacedContent>
                                <SpacedContent.Icon
                                    icon="bigCheck"
                                    color="accent"
                                />

                                <SpacedContent.Headline>
                                    Your link has been successfully created.
                                </SpacedContent.Headline>

                                <SpacedContent.Text>
                                    You can now copy the link to your clipboard
                                    by clicking the button below.
                                </SpacedContent.Text>

                                <SpacedContent.Text>
                                    Your link has the campaign ID{' '}
                                    <strong>
                                        {generateCampaignId(
                                            this.props.createdLink.createdAt
                                        )}
                                    </strong>
                                    .
                                </SpacedContent.Text>

                                <SpacedContent.Button
                                    as={CopyButton}
                                    isGhost={false}
                                    isSmall={false}
                                    copy={
                                        this.props.createdLink.shortenedUrl ||
                                        this.props.createdLink.url
                                    }
                                    then={this.onModalClose}
                                />
                            </SpacedContent>
                        ) : (
                            <Loading />
                        )}
                    </Default.ModalContent>
                </Default>
            );
        }

        const initialValues = this.getInitialValues(
            this.props.transformedSchema,
            this.props.copyUrl
        );

        return (
            <Default hasNoPadding>
                <StyledHeadline>
                    <FormattedMessage {...messages.headline} />
                </StyledHeadline>

                <Text>
                    <FormattedMessage {...messages.text} />
                </Text>

                <Formik
                    onSubmit={this.handleSubmit}
                    validate={this.validate}
                    initialValues={initialValues}
                >
                    {formik => (
                        <NewLinkForm
                            schema={this.props.categorisedSchema}
                            flatSchema={this.props.transformedSchema}
                            values={formik.values}
                            errors={formik.errors}
                            touched={formik.touched}
                            isLoading={formik.isSubmitting}
                            onSubmit={formik.handleSubmit}
                            onFieldChange={formik.handleChange}
                            onFieldBlur={formik.handleBlur}
                            setFieldValue={formik.setFieldValue}
                        />
                    )}
                </Formik>
            </Default>
        );
    };
}

export { NewLink };

export default withNewLinkContainer(NewLink);
