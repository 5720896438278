import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import LoginForm from '../../organisms/LoginForm/LoginForm';
import BoxBackground from '../../templates/BoxBackground/BoxBackground';
import withLoginContainer from './Login.container';

class Login extends React.PureComponent {
    initialValues = {
        username: '',
        password: '',
    };

    handleLogin = (values, actions) => {
        this.props.doLogin(values.username, values.password).catch(() => {
            actions.setSubmitting(false);
        });
    };

    render = () => (
        <BoxBackground>
            <Formik
                onSubmit={this.handleLogin}
                initialValues={this.initialValues}
            >
                {formik => (
                    <LoginForm
                        values={formik.values}
                        isLoading={formik.isSubmitting}
                        onSubmit={formik.handleSubmit}
                        onFieldChange={formik.handleChange}
                        onFieldBlur={formik.handleBlur}
                    />
                )}
            </Formik>
        </BoxBackground>
    );
}

Login.propTypes = {
    doLogin: PropTypes.func,
};

export { Login };

export default withLoginContainer(Login);
