import { useState } from 'react';

export const useToggle = initialState => {
    const [value, setValue] = useState(initialState);

    const toggle = () => {
        setValue(!value);
    };

    return [value, toggle];
};
