import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ifProp } from '../../../helpers/general';
import { color, fromTheme } from '../../../helpers/theme';
import Loading from '../Loading/Loading';

// Export these props so LinkButton can use them
export const buttonStyles = css`
    border: 1px solid transparent;
    border-radius: ${fromTheme('borderRadius', 'main')};
    padding: 10px 43px;
    line-height: ${fromTheme('lineHeight', 'button')};
    font-weight: ${fromTheme('fontWeight', 'medium')};
    font-size: ${fromTheme('fontSize', 'text')};

    background: ${color('primary', 'main')};
    color: ${color('primary', 'contrast')};
    border-color: ${color('primary', 'main')};
    text-decoration: none;
    display: inline-flex;

    &:hover {
        cursor: pointer;
        background: ${color('primary', 'hover')};
        border-color: ${color('primary', 'hover')};
    }

    &:active {
        outline: none;
    }

    ${ifProp(
        'isGhost',
        css`
            background: transparent;
            color: ${color('primary', 'main')};
            border-color: currentColor;

            &:hover {
                background: transparent;
                color: ${color('primary', 'hover')};
            }
        `
    )};

    ${ifProp(
        'isSmall',
        css`
            font-size: ${fromTheme('fontSize', 'text')};
            line-height: ${fromTheme('lineHeight', 'text')};
            padding: 7px 10px;
        `
    )};

    ${ifProp(
        'disabled',
        css`
            opacity: 0.5;
            cursor: not-allowed !important;
        `
    )};
`;

const StyledButton = styled.button`
    ${buttonStyles};
`;

const Button = ({ children, isDisabled, isLoading, ...rest }) => (
    <StyledButton disabled={isDisabled || isLoading} {...rest}>
        {isLoading ? <Loading /> : children}
    </StyledButton>
);

Button.propTypes = {
    /** Button text or children */
    children: PropTypes.node.isRequired,

    /** Whether to render a button with the outline only */
    isGhost: PropTypes.bool,

    /** Whether to render a smaller sized button */
    isSmall: PropTypes.bool,

    /** Whether to display the button in a disabled state */
    isDisabled: PropTypes.bool,

    /** Whether to display the button in a loading (and disabled) state */
    isLoading: PropTypes.bool,
};

Button.defaultProps = {
    isGhost: false,
    isSmall: false,
    isDisabled: false,
    isLoading: false,
};

export default React.memo(Button);
