import { defineMessages } from 'react-intl';

const messages = defineMessages({
    name: {
        id: 'app.name',
        defaultMessage: 'app.name',
    },

    logout: {
        id: 'app.logout',
        defaultMessage: 'app.logout',
    },

    loading: {
        id: 'app.loading',
        defaultMessage: 'app.loading',
    },

    copyBtn: {
        id: 'app.copyBtn',
        defaultMessage: 'app.copyBtn',
    },

    copyMsg: {
        id: 'app.copyMsg',
        defaultMessage: 'app.copyMsg',
    },

    hintLabel: {
        id: 'app.hintLabel',
        defaultMessage: 'app.hintLabel',
    },

    urlLabel: {
        id: 'app.urlLabel',
        defaultMessage: 'app.urlLabel',
    },
});

export default messages;
