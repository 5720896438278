import React from 'react';
import { render } from 'react-dom';
import StackdriverErrorReporter from 'stackdriver-errors-js';
import App from './components/App';
import ErrorBoundary from './components/ErrorBoundary';

const errorHandler = new StackdriverErrorReporter();

errorHandler.start({
    key: window.ENV_CONFIG.STACKDRIVER_API_KEY,
    projectId: window.ENV_CONFIG.STACKDRIVER_PROJECT_ID,
    service: 'eden-adam-frontend',
    disabled: !window.ENV_CONFIG.STACKDRIVER_REPORTING_ENABLED,
});

render(
    <ErrorBoundary errorHandler={errorHandler}>
        <App />
    </ErrorBoundary>,
    document.getElementById('root')
);
