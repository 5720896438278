import { InMemoryCache } from 'apollo-cache-inmemory';
import { CachePersistor } from 'apollo-cache-persist';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { RestLink } from 'apollo-link-rest';
import { withClientState } from 'apollo-link-state';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import { ADAM_API, EDEN_SSO } from '../config';
import { defaults, resolvers } from './local-resolvers';
import { authMiddleWare } from './util';

export const createClient = (withPersistor = true) => {
    const cache = new InMemoryCache();

    const stateLink = withClientState({ resolvers, cache, defaults });

    const restLink = new RestLink({
        uri: ADAM_API.URL,
        endpoints: {
            auth: EDEN_SSO.URL,
        },
        fieldNameNormalizer: /* istanbul ignore next */ key => camelCase(key),
        fieldNameDenormalizer: /* istanbul ignore next */ key => snakeCase(key),
    });

    const authLink = setContext(authMiddleWare);

    const client = new ApolloClient({
        cache,
        link: ApolloLink.from([stateLink, authLink, restLink]),
        defaultOptions: {
            watchQuery: {
                // Load everything from cache but spin off a network call
                // in parallel to update the cache in the background
                fetchPolicy: 'cache-and-network',
            },
        },
    });

    let persistor;

    if (withPersistor) {
        persistor = new CachePersistor({
            cache,
            storage: window.localStorage,
        });
    }

    return [client, persistor];
};

const [client, persistor] = createClient();

export default client;

export { persistor };
