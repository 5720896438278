import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ifProp } from '../../../helpers/general';
import { color } from '../../../helpers/theme';
import Box from '../../molecules/Box/Box';
import HeaderBar from '../../organisms/HeaderBar/HeaderBar';
import MainNav from '../../organisms/MainNav/MainNav';
import DefaultContainer from './Default.container';

const PageWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: 69px calc(100vh - 69px);
    grid-template-areas: 'header header' 'nav content';
`;

const StyledHeaderBar = styled(HeaderBar)`
    grid-area: header;
`;

const StyledMainNav = styled(MainNav)`
    grid-area: nav;
`;

const ContentArea = styled.div`
    grid-area: content;
    padding: 62px 40px;
    overflow: auto;

    ${ifProp(
        'centered',
        css`
            display: flex;
            justify-content: center;
            align-items: center;
        `
    )};

    ${ifProp(
        'noPadding',
        css`
            padding: 0;
        `
    )};
`;

const ModalBackDrop = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    /* Support for this is 💩 so will probably be replaced by svg eventually */
    backdrop-filter: blur(5px);
    background-color: ${color('modalBackdrop')};
    display: flex;
    justify-content: center;
    align-items: center;
`;

/**
 * Utility to create Vue-like slots
 * @param {number} number
 * @returns {array}
 */
const createSlots = number => {
    const slots = [];

    for (let i = 0; i < number; i++) {
        /* istanbul ignore next */
        const Component = () => null;

        slots.push({
            Component,
            fill: children => {
                const wrapper = Array.isArray(children)
                    ? children.find(child => child && child.type === Component)
                    : children.type === Component
                    ? children
                    : null;

                if (wrapper && wrapper.props) {
                    return wrapper.props.children;
                } else {
                    return null;
                }
            },
        });
    }

    const allSlotTypes = slots.map(slot => slot.Component);

    slots.push({
        fill: children =>
            Array.isArray(children)
                ? children.filter(
                      child => child && !allSlotTypes.includes(child.type)
                  )
                : !allSlotTypes.includes(children.type)
                ? children
                : null,
    });

    return slots;
};

const [modalContentSlot, defaultSlot] = createSlots(1);

const Default = React.memo(
    ({
        children,
        isCentered,
        hasNoPadding,
        isModalOpen,
        onModalClose,
        isLoggedIn,
        doLogout,
    }) => {
        const modalContent = modalContentSlot.fill(children);

        return (
            <React.Fragment>
                <PageWrapper>
                    <StyledHeaderBar
                        withLogout={isLoggedIn}
                        onLogout={doLogout}
                    />

                    <StyledMainNav />

                    <ContentArea centered={isCentered} noPadding={hasNoPadding}>
                        {defaultSlot.fill(children)}
                    </ContentArea>
                </PageWrapper>

                {isModalOpen && modalContent && (
                    <ModalBackDrop
                        onClick={onModalClose}
                        data-testid="modal-backdrop"
                    >
                        <Box>{modalContent}</Box>
                    </ModalBackDrop>
                )}
            </React.Fragment>
        );
    }
);

Default.propTypes = {
    /** Main page content */
    children: PropTypes.node.isRequired,

    /** Whether to center the given content within the content area */
    isCentered: PropTypes.bool,

    /** Whether to open the modal  */
    isModalOpen: PropTypes.bool,

    /** Whether to remove padding for the content */
    hasNoPadding: PropTypes.bool,

    /** Handler to call when the modal closes */
    onModalClose: PropTypes.func,

    /** Whether the user is logged in */
    isLoggedIn: PropTypes.bool,

    /** Logout handler */
    doLogout: PropTypes.func,
};

Default.ModalContent = modalContentSlot.Component;
Default.preserveStatic = ['ModalContent'];

export { Default };
export default DefaultContainer(Default);
