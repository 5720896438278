import PropTypes from 'prop-types';
import React from 'react';
import ReactDatePicker from 'react-date-picker/dist/entry.nostyle';
import styled, { css } from 'styled-components/macro';
import { formatAsISODate, ifProp } from '../../../helpers/general';
import { color, fromTheme, toRem } from '../../../helpers/theme';
import Icon from '../Icon/Icon';

const DATE_PICKER = 'react-date-picker';
const CALENDAR = 'react-calendar';

const StyledDatePicker = styled(ReactDatePicker)`
    background: ${color('mainInput', 'background')};
    border: 2px solid ${color('mainInput', 'border')};
    border-radius: ${fromTheme('borderRadius', 'main')};
    height: 41px;
    display: flex;
    align-items: center;
    padding-left: 13px;
    padding-right: 11px;
    position: relative;

    ${ifProp(
        'hasError',
        css`
            background: ${color('error', 'bg')};
            border-color: ${color('error', 'main')};
        `
    )};

    .${DATE_PICKER}__wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .${DATE_PICKER}__inputGroup {
        display: inline-flex;
        align-items: center;

        &__input {
            font-size: 1rem;
            color: ${color('mainInput', 'color')};
            border: none;
            background: transparent;
            min-width: 0.54em;

            -moz-appearance: textfield;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            ::placeholder {
                color: ${color('mainInput', 'placeholder')};
            }
        }

        &__divider {
            padding: 0 1px;
        }
    }

    .${DATE_PICKER}__button {
        border: 0;
        background: transparent;
        cursor: pointer;
    }

    .${DATE_PICKER}__calendar {
        width: 360px;
        max-width: 100vw;
        position: absolute;
        left: -2px;
        top: 100%;
        z-index: 2;

        &--closed {
            display: none;
        }
    }

    .${CALENDAR}__navigation {
        height: 68px;
        display: flex;
        align-items: center;
        background: ${color('dateInput', 'calendarNavBg')};
        border: 1px solid ${color('dateInput', 'calendarNavBorder')};
        padding: 0 44px;
        cursor: auto;

        &__arrow,
        &__label {
            background: none;
            border: none;
            color: ${color('dateInput', 'calendarNavColor')};
            font-size: ${fromTheme('fontSize', 'nav')};
            line-height: ${fromTheme('fontSize', 'nav')};
            font-weight: ${fromTheme('fontWeight', 'bold')};
        }

        &__arrow {
            cursor: pointer;
        }

        &__label {
            pointer-events: none;
        }
    }

    .${CALENDAR}__month-view {
        background: ${color('dateInput', 'calendarBodyBg')};
        border: 1px solid ${color('dateInput', 'calendarBodyBorder')};
        border-top: none;
        padding: 28px 37px 25px;

        &__weekdays {
            margin-bottom: 22px;
            text-align: center;
            font-size: ${toRem(12)};
            line-height: ${toRem(14)};
            cursor: auto;
        }

        &__days__day--neighboringMonth {
            opacity: 0.4;
        }
    }

    .${CALENDAR}__tile {
        border: none;
        background: transparent;
        font-size: ${toRem(13)};
        line-height: ${toRem(15)};
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        color: ${color('dateInput', 'calendarBodyColor')};

        &:active {
            outline: none;
        }

        > time {
            display: flex;
            width: 30px;
            height: 30px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid transparent;
            cursor: pointer;

            &:hover {
                border-color: ${color('accent2', 'main')};
            }
        }

        &--active > time {
            background: ${color('accent2', 'main')};
            color ${color('accent2', 'contrast')};
            border-color: ${color('accent2', 'main')};
        }

        &--now > time {
            background: ${color('accent', 'main')};
            color ${color('accent', 'contrast')};
            border-color: ${color('accent', 'main')};
        }
    }
`;

const DatePicker = ({ value, name, onChange, onBlur, hasError }) => (
    <StyledDatePicker
        value={value && value.length === 10 ? new Date(value) : value}
        onChange={newValue =>
            onChange &&
            onChange({
                target: {
                    type: 'date',
                    name,
                    value:
                        newValue instanceof Date
                            ? formatAsISODate(newValue)
                            : newValue,
                },
            })
        }
        onBlur={onBlur}
        hasError={hasError}
        calendarIcon={<Icon icon="calendar" />}
        nextLabel={<Icon icon="calendarArrow" rotate={180} />}
        prevLabel={<Icon icon="calendarArrow" />}
        // disable a bunch of controls we don't need (for now)
        clearIcon={null}
        next2Label={null}
        prev2Label={null}
    />
);

DatePicker.propTypes = {
    /** Input name */
    name: PropTypes.string.isRequired,

    /** Current date value */
    value: PropTypes.any,

    /** Handler for change event */
    onChange: PropTypes.func,

    /** Hadler for blur event  */
    onBlur: PropTypes.func,

    /** Whether to display an error state */
    hasError: PropTypes.bool,
};

DatePicker.defaultProps = {
    hasError: false,
};

export default DatePicker;
