import { defineMessages } from 'react-intl';

const messages = defineMessages({
    headline: {
        id: 'home.headline',
        defaultMessage: 'home.headline',
    },

    headlineForNoMoreLinks: {
        id: 'home.headlineForNoMoreLinks',
        defaultMessage: 'home.headlineForNoMoreLinks',
    },

    endOfList: {
        id: 'home.endOfList',
        defaultMessage: 'home.endOfList',
    },

    text: {
        id: 'home.text',
        defaultMessage: 'home.text',
    },

    btnText: {
        id: 'home.btnText',
        defaultMessage: 'home.btnText',
    },

    prevPage: {
        id: 'home.prevPage',
        defaultMessage: 'home.prevPage',
    },

    nextPage: {
        id: 'home.nextPage',
        defaultMessage: 'home.nextPage',
    },

    goBack: {
        id: 'home.goBack',
        defaultMessage: 'home.goBack',
    },
});

export default messages;
