import { defineMessages } from 'react-intl';

const messages = defineMessages({
    itemLinkList: {
        id: 'mainNav.itemLinkList',
        defaultMessage: 'mainNav.itemLinkList',
    },
});

export default messages;
