import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { color, fromTheme } from '../../../helpers/theme';
import messages from '../../App.messages';
import TextLogo from '../../molecules/TextLogo/TextLogo';

const Header = styled.header`
    background: ${color('header', 'main')};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    border-bottom: 1px solid ${color('header', 'border')};
    color: ${color('header', 'contrast')};
`;

const LogoutButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    font-size: ${fromTheme('fontSize', 'text')};
    color: currentColor;
    opacity: 0.4;

    &:hover {
        opacity: 1;
    }
`;

const LogoLink = styled(Link)`
    text-decoration: none;
`;

const HeaderBar = ({ withLogout, onLogout, ...props }) => (
    <Header {...props}>
        <LogoLink to="/">
            <TextLogo />
        </LogoLink>

        {withLogout && (
            <LogoutButton onClick={onLogout}>
                <FormattedMessage {...messages.logout} />
            </LogoutButton>
        )}
    </Header>
);

HeaderBar.propTypes = {
    /** Whether the logout button should be displayed */
    withLogout: PropTypes.bool,

    /** Handler for click on logout */
    onLogout: PropTypes.func,
};

HeaderBar.defaultProps = {
    withLogout: false,
};

export default HeaderBar;
