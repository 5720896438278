import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { filterProps, ifProp } from '../../../helpers/general';
import { color, fromTheme } from '../../../helpers/theme';
import Icon from '../../atoms/Icon/Icon';
import { SORT_DIRECTION } from '../../pages/Home/Home.container';

const StyledTable = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    color: ${color('table', 'text')};
`;

const TableHeadCell = styled.th`
    border-bottom: 1px solid ${color('table', 'border')};
    height: 43px;
    text-align: left;
    line-height: ${fromTheme('lineHeight', 'text')};
    vertical-align: top;
    color: ${color('table', 'header')};
    font-weight: ${fromTheme('fontWeight', 'bold')};
    padding-right: 15px;
    white-space: nowrap;

    ${ifProp(
        'sortable',
        css`
            &:hover {
                cursor: pointer;
            }
        `
    )};

    ${ifProp(
        'sorting',
        css`
            color: ${color('table', 'headerHighlighted')};
        `
    )};
`;

const SortIcon = styled(filterProps(Icon, ['isFlipped']))`
    margin-left: 10px;

    ${ifProp(
        'isFlipped',
        css`
            transform: rotate(180deg);
        `
    )};
`;

const TableCell = styled.td`
    border-bottom: 1px solid ${color('table', 'border')};
    height: 60px;
    text-align: left;
    font-size: ${fromTheme('fontSize', 'table')};
    line-height: ${fromTheme('lineHeight', 'table')};
    padding-right: 15px;

    &:last-child {
        /*
            this will shrink the cell as much as possible
            without breaking between the buttons
        */
        width: 1px;
        white-space: nowrap;
        padding-right: 0;
    }

    ${ifProp(
        'as',
        'th',
        css`
            color: ${color('table', 'headerHighlighted')};
        `
    )};
`;

class Table extends React.PureComponent {
    static propTypes = {
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                accessor: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.func,
                ]).isRequired,
            })
        ).isRequired,

        rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    };

    /**
     * Access content for a cell defined by
     * - a row (or its index)
     * - a column (or its index)
     *
     * @param {object|int} rowOrIndex
     * @param {object|int} columnOrIndex
     * @returns {any}
     */
    getCellContent = (rowOrIndex, columnOrIndex) => {
        const { columns, rows } = this.props;

        const row =
            typeof rowOrIndex === 'number' ? rows[rowOrIndex] : rowOrIndex;

        const column =
            typeof columnOrIndex === 'number'
                ? columns[columnOrIndex]
                : columnOrIndex;

        return typeof column.accessor === 'function'
            ? column.accessor(row)
            : row[column.accessor] || row[column.accessor.toLowerCase()];
    };

    render = () => {
        const { columns, rows, sortedBy, sortDirection, sortBy, ...props } =
            this.props;

        return (
            <StyledTable {...props}>
                <thead>
                    <tr>
                        {columns.map(column => (
                            <TableHeadCell
                                key={`Table column ${column.name}`}
                                sortable={!!column.sortKey}
                                sorting={sortedBy === column.sortKey}
                                onClick={
                                    !!column.sortKey
                                        ? () => sortBy(column.sortKey)
                                        : null
                                }
                            >
                                {column.name}

                                {!!column.sortKey && (
                                    <SortIcon
                                        icon="expandMore"
                                        isFlipped={
                                            sortedBy === column.sortKey &&
                                            sortDirection ===
                                                SORT_DIRECTION.DESC
                                        }
                                    />
                                )}
                            </TableHeadCell>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => {
                        const rowKey = row.id || rowIndex;

                        return (
                            <tr key={`Table row ${rowKey}`}>
                                {columns.map(column => {
                                    const cellKey = `${rowKey} ${column.name}`;

                                    const cellTag = column.rowHeading
                                        ? 'th'
                                        : 'td';

                                    return (
                                        <TableCell
                                            as={cellTag}
                                            key={`Table cell ${cellKey}`}
                                        >
                                            {this.getCellContent(row, column)}
                                        </TableCell>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </StyledTable>
        );
    };
}

export default Table;
