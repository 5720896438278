import Home from './components/pages/Home/Home';
import Login from './components/pages/Login/Login';
import NewLink from './components/pages/NewLink/NewLink';
import NotFound from './components/pages/NotFound/NotFound';
import ShowLink from './components/pages/ShowLink/ShowLink';

export default {
    HOME: {
        path: '/',
        component: Home,
    },
    CREATE_LINK: {
        path: '/new',
        component: NewLink,
    },
    SHOW_LINK: {
        path: '/link/:id',
        component: ShowLink,
    },
    LOGIN: {
        path: '/login',
        component: Login,
        public: true,
    },
    NOT_FOUND: {
        component: NotFound,
        public: true,
    },
};
