import { defineMessages } from 'react-intl';

const messages = defineMessages({
    btnText: {
        id: 'newLinkForm.btnText',
        defaultMessage: 'newLinkForm.btnText',
    },
});

export default messages;
