import client, { persistor } from '.';
import { EDEN_SSO } from '../config';
import { camelCaseKeys } from '../helpers/general';
import { GET_USER_QUERY } from './modules/auth';

/**
 * Attempt to hydrate the cache from storage.
 *
 * If this fails, the cache won't be persisted,
 * so all data (incl. login) will be gone on page reload.
 */
export const hydrateCache = async () => {
    try {
        await persistor.restore();
    } catch (err) {
        // Do nothing - continuing without persistence
    }
};

/**
 * Set data to a "logged in" state within the Apollo Cache.
 *
 * This is necessary since we're working against a REST API
 * and don't have a proper GraphQL schema to work with.
 */
export const handleLogin = data => {
    // Add __typename field to data
    const loginData = {
        ...data.loginData,
        __typename: 'LoginData',
    };

    // Write the data as the new "result" for the GetUser query
    client.writeQuery({
        query: GET_USER_QUERY,
        data: { loginData },
    });

    // Resume data persistence since we're not logged in
    persistor.resume();
};

/**
 * Set data to a "logged out" state within the Apollo Cache.
 *
 * This is necessary since we're working against a REST API
 * and don't have a proper GraphQL schema to work with.
 */
export const handleLogout = async () => {
    // No data persistence in logged out state
    persistor.pause();

    // Reset all fields manually and add __typename so it matches the existing cache
    const loginData = {
        accessToken: '',
        expiresIn: '',
        createdAt: '',
        refreshToken: '',
        __typename: 'LoginData',
    };

    // Write empty login data once, so the user is logged out
    await client.writeQuery({
        query: GET_USER_QUERY,
        data: { loginData },
    });

    // Reset all data - but then the empty login data would be missing so..
    await client.resetStore();

    // Write empty login data again so Apollo is happy.
    await client.writeQuery({
        query: GET_USER_QUERY,
        data: { loginData },
    });

    // Remove any and all data from persisten cache
    await persistor.purge();
};

/**
 * Get current access token or refresh token from Apollo Cache.
 *
 * If there is a token and it's expired, only the current refresh token is returned.
 */
export const getTokenFromCache = () => {
    let loginData;

    try {
        const data = client.readQuery({
            query: GET_USER_QUERY,
        });

        loginData = data.loginData;
    } catch (err) {
        // return nothing if there's any kind of error reading the login data from the cache
        return {};
    }

    const token = loginData ? loginData.accessToken : null;

    if (token) {
        const now = Date.now();
        const expiryTimestamp =
            (loginData.createdAt + loginData.expiresIn) * 1000;

        if (now > expiryTimestamp) {
            return { refreshToken: loginData.refreshToken };
        } else {
            return { token };
        }
    }

    // return nothing if no token is set at all (unlikely though..)
    return {};
};

/**
 * Build the necessary authorization headers from auth token.
 */
export const buildAuthHeader = token => ({
    headers: {
        Authorization: `Bearer ${token}`,
    },
});

/**
 * Fetch a new token from the API with the refresh token.
 *
 * This mimics calling a GraphQL endpoint by camelCasing all keys.
 * If the response is an error, will throw the error so then/catch
 * can work properly on the other side of the Promise.
 */
export const fetchNewToken = refreshToken =>
    fetch(`${EDEN_SSO.URL}/oauth/token`, {
        method: 'POST',
        body: JSON.stringify({
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
        }),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then(camelCaseKeys)
        .then(response => {
            if (response.error) {
                throw new Error(response.error);
            }

            return response;
        });

/**
 * Middleware for Apollo to add Authorication headers if applicable
 * Will check the stored token for validity and perform a token refresh if necessary
 */
export const authMiddleWare = () =>
    new Promise((resolve, reject) => {
        const { token, refreshToken } = getTokenFromCache();

        if (token) {
            return resolve(buildAuthHeader(token));
        }

        if (refreshToken) {
            return (
                fetchNewToken(refreshToken)
                    .then(response => {
                        // Save the new token, expiry etc to app cache
                        handleLogin({ loginData: response });

                        // then resolve with authorization headers with the new token
                        return resolve(buildAuthHeader(response.accessToken));
                    })
                    // If there's an error fetching the new token, that should
                    // be displayed in the UI somewhere
                    .catch(reject)
            );
        }

        // if there is no token at all, we're probably not logged in so
        // no need for an authorization header
        return resolve();
    });
