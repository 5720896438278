import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { ifProp } from '../../../helpers/general';
import { color, fromTheme } from '../../../helpers/theme';

const TopAddon = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -60px;
`;

const Box = styled.div`
    background: ${color('box', 'background')};
    border: 1px solid ${color('box', 'border')};
    border-radius: ${fromTheme('borderRadius', 'big')};
    max-width: 500px;
    width: 100%;
    min-height: 422px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: -10px 9px 21px 0 ${color('box', 'shadow')};
    position: relative;
    text-align: center;

    ${ifProp(
        'isFlexible',
        css`
            max-width: none;
            min-height: 0;
            padding: 47px 56px;
        `
    )};

    ${ifProp(
        'hasNoBorder',
        css`
            border: none;
        `
    )};

    ${ifProp(
        'hasTopAddon',
        css`
            padding-top: 86px;
        `
    )};
`;

Box.propTypes = {
    /** Box content */
    children: PropTypes.node.isRequired,

    /** Whether to render a flexible box */
    isFlexible: PropTypes.bool,

    /** Whether to render the box without the usual border */
    hasNoBorder: PropTypes.bool,

    /** Whether the box has an addon on top */
    hasTopAddon: PropTypes.bool,
};

Box.defaultProps = {
    isFlexible: false,
    hasNoBorder: false,
    hasTopAddon: false,
};

Box.TopAddon = TopAddon;

export default Box;
