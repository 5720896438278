import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import { ifProp } from '../../../helpers/general';
import {
    resetNestedFields,
    setSingleValueFields,
} from '../../../helpers/schema';
import appMessages from '../../App.messages';
import Button from '../../atoms/Button/Button';
import Title from '../../atoms/Title/Title';
import FormField from '../../molecules/FormField/FormField';
import TwoColumns from '../../templates/TwoColumns/TwoColumns';
import messages from './NewLinkForm.messages';

const FormRow = styled(TwoColumns)`
    margin-bottom: 29px;
`;

const Container = styled.div`
    padding: 42px 128px 55px;

    ${ifProp(
        'grey',
        css`
            background: #f9f9f9;
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
        `
    )};
`;

const StyledTitle = styled(Title)`
    margin-bottom: 1em;
`;

const StyledButton = styled(Button)`
    justify-self: end;
`;

const switchFieldType = type => {
    switch (type) {
        case 'collection':
            return 'select';
        case 'date':
            return 'date';
        default:
            return 'text';
    }
};

/* istanbul ignore next */
const preventSubmit = e => e.preventDefault();

class NewLinkForm extends React.PureComponent {
    /**
     * When values change make sure we reset nested fields as necessary
     */
    componentDidUpdate = prevProps => {
        resetNestedFields(
            this.props.flatSchema,
            this.props.values,
            prevProps.values,
            this.props.setFieldValue
        );

        setSingleValueFields(
            this.props.flatSchema,
            this.props.values,
            this.props.setFieldValue
        );
    };

    /**
     * Recursively render fields
     */
    renderFieldWithNested = field => {
        const { values, errors, touched, onFieldChange, onFieldBlur } =
            this.props;

        const fieldType = switchFieldType(field.kind);

        const fieldOptions = field.getValues
            ? field.getValues(values)
            : field.values;

        return [
            <FormRow key={`Form row ${field.key}`}>
                <FormField
                    value={values[field.key]}
                    hasError={!!errors[field.key] && !!touched[field.key]}
                    onChange={onFieldChange}
                    onBlur={onFieldBlur}
                    type={fieldType}
                    label={field.title}
                    name={field.key}
                    options={fieldOptions}
                    isDisabled={fieldType === 'select' && !fieldOptions.length}
                    hint={field.description}
                />
            </FormRow>,
            // Recursion go!
            ...field.nestedFields.map(this.renderFieldWithNested),
        ];
    };

    onUrlBlur = (event, onFieldBlur, onFieldChange) => {
        // account for forms not hooked up to formik
        if (!onFieldBlur || !onFieldChange) {
            return;
        }

        const value = event.target.value;

        // if the URL does not start with http:// or https:// prefix it automatically
        if (value.indexOf('http://') !== 0 && value.indexOf('https://') !== 0) {
            // trigger a change event before the blur
            // providing the target is enough for formik
            onFieldChange({
                target: {
                    type: 'text',
                    name: 'url',
                    value: 'https://' + value,
                },
            });
        }

        return onFieldBlur(event);
    };

    render = () => {
        const {
            intl,
            schema,
            flatSchema,
            isLoading,
            values,
            errors,
            touched,
            onFieldChange,
            onFieldBlur,
            setFieldValue,
            onSubmit,
            ...formProps
        } = this.props;

        return (
            <form
                {...formProps}
                onSubmit={isLoading ? preventSubmit : onSubmit}
            >
                <Container>
                    <FormRow>
                        <FormField
                            value={values.url}
                            hasError={!!errors.url && !!touched.url}
                            onChange={onFieldChange}
                            onBlur={event => {
                                this.onUrlBlur(
                                    event,
                                    onFieldBlur,
                                    onFieldChange
                                );
                            }}
                            type="text"
                            label={intl.formatMessage(appMessages.urlLabel)}
                            name="url"
                        />
                    </FormRow>
                </Container>

                {schema.map((category, index) => (
                    <Container
                        grey={index % 2 === 0}
                        key={`Field Container ${category.name}`}
                    >
                        <StyledTitle>{category.name}</StyledTitle>
                        {category.fields.map(this.renderFieldWithNested)}
                    </Container>
                ))}

                <Container>
                    <FormRow>
                        <StyledButton isLoading={isLoading} type="submit">
                            <FormattedMessage {...messages.btnText} />
                        </StyledButton>
                    </FormRow>
                </Container>
            </form>
        );
    };
}

NewLinkForm.propTypes = {
    /** Schema of the form to build */
    schema: PropTypes.array.isRequired,

    /** Schema without categories */
    flatSchema: PropTypes.array.isRequired,

    /** Form submit handler */
    onSubmit: PropTypes.func.isRequired,

    /** Whether to display the form in a loading state, disabling submit */
    isLoading: PropTypes.bool,

    /** Values of the form fields */
    values: PropTypes.object,

    /** Errors of the form fields */
    errors: PropTypes.object,

    /** Touched states of the form fields */
    touched: PropTypes.object,

    /** Field change handler */
    onFieldChange: PropTypes.func,

    /** Field blur handler */
    onFieldBlur: PropTypes.func,

    /** intl library inject */
    intl: intlShape,
};

NewLinkForm.defaultProps = {
    isLoading: false,
    values: {},
    errors: {},
    touched: {},
};

export default injectIntl(NewLinkForm);
