import PropTypes from 'prop-types';
import React from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import localeData from '../locale-data';
import translations from '../translations';

/**
 * Wrapper for translated strings
 * React.Fragment doesn't support dangerouslySetInnerHTML, so use a span everytime it is used
 * e.g. when using FormattedHTMLMessage
 */
const TranslationWrapper = ({ dangerouslySetInnerHTML, ...props }) =>
    dangerouslySetInnerHTML ? (
        <span dangerouslySetInnerHTML={dangerouslySetInnerHTML} {...props} />
    ) : (
        <React.Fragment {...props} />
    );

class I18nProvider extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        locale: PropTypes.oneOf(Object.keys(localeData)).isRequired,
    };

    constructor(props) {
        super(props);

        Object.keys(localeData).forEach(locale =>
            addLocaleData(localeData[locale])
        );
    }

    render = () => {
        const { children, locale } = this.props;

        return (
            <IntlProvider
                locale={locale}
                key={locale}
                messages={translations[locale]}
                textComponent={TranslationWrapper}
            >
                {children}
            </IntlProvider>
        );
    };
}

export default I18nProvider;
