import { defineMessages } from 'react-intl';

const messages = defineMessages({
    headline: {
        id: 'notFound.headline',
        defaultMessage: 'notFound.headline',
    },

    text: {
        id: 'notFound.text',
        defaultMessage: 'notFound.text',
    },

    btnText: {
        id: 'notFound.btnText',
        defaultMessage: 'notFound.btnText',
    },
});

export default messages;
