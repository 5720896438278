import { createGlobalStyle } from 'styled-components/macro';
import { BASE_FONT_SIZE } from '../config';
import { color, fromTheme } from '../helpers/theme';

const assumedBrowserDefaultFontSize = 16;

const GlobalStyle = createGlobalStyle`
    html {
        color: ${color('text')};
        font-family: ${fromTheme('fontFamily')};
        font-size: ${(BASE_FONT_SIZE / assumedBrowserDefaultFontSize) * 100}%;
        font-weight: ${fromTheme('fontWeight', 'regular')};
        line-height: ${fromTheme('lineHeight', 'text')};
    }

    *, *::before, *::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    /* Remove visible outline for non-keyboard users */
    :focus:not(:focus-visible) { outline: none }
`;

export default GlobalStyle;
