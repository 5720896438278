import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { URI_LIST_PAGE_SIZE } from '../../config';

// TODO: create a fragment for the URLs

export const GET_ALL_URIS_QUERY = gql`
    query GetAllUris($sortBy: string!, $sortDirection: string!, $page: number!) {
        uris(sortBy: $sortBy, sortDirection: $sortDirection, page: $page)
            @rest(
                type: "[Uri]"
                path: "/uris?q[s]={args.sortBy}+{args.sortDirection}&per=${URI_LIST_PAGE_SIZE}&page={args.page}"
                method: "GET"
            ) {
            id
            url
            shortenedUrl
            createdAt
            parameters @type(name: "Parameter") {
                id
                key
                value
                presetId
                presetValueId
            }
        }
    }
`;

export const getAllUrisQuery = graphql(GET_ALL_URIS_QUERY, {
    name: 'getAllUris',
});

export const GET_ONE_URI_QUERY = gql`
    query GetOneUri($id: Identity!) {
        uri(id: $id)
            @rest(type: "Uri", path: "/uris/{args.id}", method: "GET") {
            id
            url
            shortenedUrl
            createdAt
            parameters @type(name: "Parameter") {
                id
                key
                value
                presetId
                presetValueId
            }
        }
    }
`;

export const getOneUriQuery = (
    getOptionsFromProps,
    skipIfProps = () => false
) =>
    graphql(GET_ONE_URI_QUERY, {
        name: 'getOneUri',
        options: getOptionsFromProps,
        skip: skipIfProps,
    });

export const GET_NEW_SCHEMA_QUERY = gql`
    query GetNewSchema {
        schema
            @rest(type: "UriSchemaWrapper", path: "/uris/new", method: "GET") {
            uri @type(name: "Uri") {
                parametersAttributes @type(name: "Preset") {
                    id
                    key
                    kind
                    parentValueId
                    position
                    titleDe
                    titleEn
                    descriptionDe
                    descriptionEn
                    category @type(name: "PresetCategory") {
                        id
                        nameDe
                        nameEn
                        descriptionDe
                        descriptionEn
                    }
                    values @type(name: "PresetValue") {
                        id
                        value
                    }
                }
            }
        }
    }
`;

export const getNewSchemaQuery = graphql(GET_NEW_SCHEMA_QUERY, {
    name: 'getNewSchema',
});

export const CREATE_URI_MUTATION = gql`
    mutation CreateLink {
        uri(input: $input) @rest(type: "Uri", path: "/uris", method: "POST") {
            id
            url
            shortenedUrl
            parameters @type(name: "Parameter") {
                id
                key
                value
            }
        }
    }
`;

export const createUriMutation = graphql(CREATE_URI_MUTATION, {
    name: 'createUri',
});
