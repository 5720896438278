import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ifProp } from '../../../helpers/general';
import { color, fromTheme } from '../../../helpers/theme';
import checkboxCheckedAsset from './assets/checkbox.svg';

const StyledCheckbox = styled.span`
    display: block;
    width: 18px;
    height: 18px;
    border: 2px solid ${color('checkboxInput', 'border')};
    border-radius: 2px;

    ${ifProp(
        'hasError',
        css`
            border-color: ${color('error', 'main')};
            background: ${color('error', 'bg')};
        `
    )};
`;

const StyledInput = styled.input`
    padding: 11px 15px 10px;
    background: ${color('mainInput', 'background')};
    border: 2px solid ${color('mainInput', 'border')};
    border-radius: ${fromTheme('borderRadius', 'main')};
    font-size: ${fromTheme('fontSize', 'text')};
    line-height: ${fromTheme('lineHeight', 'text')};
    display: block;
    width: 100%;

    ::placeholder {
        color: ${color('mainInput', 'placeholder')};
        font-weight: ${fromTheme('fontWeight', 'regular')};
    }

    ${ifProp(
        'isGrey',
        css`
            padding: 12px 15px 11px;
            background: ${color('greyInput', 'background')};
            border-color: ${color('greyInput', 'border')};
            border-width: 1px;

            ::placeholder {
                color: ${color('greyInput', 'placeholder')};
            }
        `
    )};

    ${ifProp(
        'type',
        'checkbox',
        css`
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;

            &:checked + ${StyledCheckbox} {
                background: url(${checkboxCheckedAsset});
                border: none;
            }
        `
    )};

    ${ifProp(
        'hasError',
        css`
            border-color: ${color('error', 'main')};
            background: ${color('error', 'bg')};
        `
    )};
`;

const Input = ({ type, options, onChange, onBlur, isDisabled, ...props }) => (
    <React.Fragment>
        <StyledInput
            onChange={onChange}
            onBlur={onBlur}
            type={type}
            aria-invalid={props.hasError}
            disabled={isDisabled}
            {...props}
        />

        {type === 'checkbox' && <StyledCheckbox hasError={props.hasError} />}
    </React.Fragment>
);

Input.propTypes = {
    /** Input type */
    type: PropTypes.oneOf(['text', 'password', 'checkbox', 'select', 'date']),

    /** Input name */
    name: PropTypes.string.isRequired,

    /** Input placeholder */
    placeholder: PropTypes.string,

    /** Handler for input change event */
    onChange: PropTypes.func,

    /** Handler for input blur event */
    onBlur: PropTypes.func,

    /** Whether to display the grey version */
    isGrey: PropTypes.bool,

    /** Whether to display the input in an error state */
    hasError: PropTypes.bool,

    /** Options if the input is a select */
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
};

Input.defaultProps = {
    type: 'text',
    placeholder: '',
    isGrey: false,
    hasError: false,
    options: [],
};

export default Input;
