import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export const GET_LOCALE_QUERY = gql`
    query GetLocale {
        locale @client
    }
`;

export const getLocaleQuery = graphql(GET_LOCALE_QUERY, {
    name: 'getLocale',
});

export const SET_LOCALE_MUTATION = gql`
    mutation SetLocale {
        locale(locale: $locale) @client
    }
`;

export const setLocaleMutation = graphql(SET_LOCALE_MUTATION, {
    name: 'setLocale',
});
