import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import Logo from '../../atoms/Logo/Logo';
import Box from '../../molecules/Box/Box';
import backgroundAsset from './assets/background.svg';

const PageWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(${backgroundAsset});
    background-size: cover;
`;

const StyledBox = styled(Box)`
    width: 100vw;
    max-width: 536px;
    margin: 15px;
`;

const BoxBackground = ({ children }) => (
    <PageWrapper>
        <StyledBox hasTopAddon isFlexible hasNoBorder>
            <Box.TopAddon as={Logo} />
            {children}
        </StyledBox>
    </PageWrapper>
);

BoxBackground.propTypes = {
    /** Box content */
    children: PropTypes.node.isRequired,
};

export default BoxBackground;
