import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import SnackbarContext from '../../../context/SnackbarContext';
import { copyToClipboard } from '../../../helpers/general';
import messages from '../../App.messages';
import Button from '../../atoms/Button/Button';

const CopyButton = ({ copy, notify, then, children, intl, ...props }) => {
    const snackbars = useContext(SnackbarContext);

    const onClickHandler = e => {
        e.preventDefault();
        e.stopPropagation();

        copyToClipboard(copy);

        if (notify) {
            snackbars.addTemp(intl.formatMessage(messages.copyMsg), 'info');
        }

        if (then) {
            then();
        }
    };

    return (
        <Button onClick={onClickHandler} {...props}>
            {children || <FormattedMessage {...messages.copyBtn} />}
        </Button>
    );
};

CopyButton.propTypes = {
    /** Text to copy on click */
    copy: PropTypes.string.isRequired,

    /** Whether to show a success notification after the text has been copied */
    notify: PropTypes.bool,

    /** "Callback" to run after the link has been copied */
    then: PropTypes.func,

    /** intl library inject */
    intl: intlShape,
};

CopyButton.defaultProps = {
    notify: true,
    // Button props
    isSmall: true,
    isGhost: true,
};

export default injectIntl(CopyButton);
