import PropTypes from 'prop-types';
import React from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import styled from 'styled-components/macro';
import { useToggle } from '../../../hooks';
import Icon from '../../atoms/Icon/Icon';
import Tooltip from '../../atoms/Tooltip/Tooltip';

// This cannot be an actual button as react-select will do weird things to it
const TriggerButton = styled.span`
    border: none;
    background: none;
    line-height: 1;
    height: 17px;
    width: 17px;
`;

const placementArrowPositionMap = {
    left: 'right',
    right: 'left',
    top: 'bottom',
    bottom: 'top',
};

const TooltipWrapper = styled.div`
    padding: 11px;
    cursor: default;
`;

const HintWrapper = styled.div`
    display: inline-flex;
    position: relative;
    z-index: 1;
    align-items: center;
`;

const mapArrowPlacement = placement => placementArrowPositionMap[placement];

const Hint = ({ children, label, ...props }) => {
    const [open, toggle] = useToggle(false); // for hover
    const [permanentOpen, togglePermanent] = useToggle(false); // for click

    return (
        <Manager>
            <HintWrapper
                onMouseEnter={toggle}
                onMouseLeave={toggle}
                onFocus={toggle}
                onBlur={toggle}
                {...props}
            >
                <Reference>
                    {({ ref }) => (
                        <TriggerButton
                            ref={ref}
                            onClick={togglePermanent}
                            aria-label={label}
                            tabIndex="0"
                        >
                            <Icon icon="question" />
                        </TriggerButton>
                    )}
                </Reference>

                {/* if the user has actually clicked on the question mark, keep this thing open even on mouse out */}
                {(permanentOpen || open) && (
                    <Popper placement="right" positionFixed={true}>
                        {({ ref, placement, style }) => (
                            <TooltipWrapper ref={ref} style={style}>
                                <Tooltip
                                    arrowPosition={mapArrowPlacement(placement)}
                                    isDark
                                >
                                    {children}
                                </Tooltip>
                            </TooltipWrapper>
                        )}
                    </Popper>
                )}
            </HintWrapper>
        </Manager>
    );
};

Hint.propTypes = {
    /** The content of the tooltip */
    children: PropTypes.node.isRequired,

    /** Label for the trigger element */
    label: PropTypes.string,
};

Hint.defaultProps = {
    label: 'Open help',
};

export default Hint;
