import { defineMessages } from 'react-intl';

const messages = defineMessages({
    headline: {
        id: 'loginForm.headline',
        defaultMessage: 'loginForm.headline',
    },

    labelUsername: {
        id: 'loginForm.labelUsername',
        defaultMessage: 'loginForm.labelUsername',
    },

    labelPassword: {
        id: 'loginForm.labelPassword',
        defaultMessage: 'loginForm.labelPassword',
    },

    btnText: {
        id: 'loginForm.btnText',
        defaultMessage: 'loginForm.btnText',
    },

    forgotPasswordText: {
        id: 'loginForm.forgotPasswordText',
        defaultMessage: 'loginForm.forgotPasswordText',
    },
});

export default messages;
