import { toRem } from '../helpers/theme';

export const COLORS = {
    WHITE: '#ffffff',
    BLACK: '#000000',

    BLUE: '#3e495e',
    BLUE_LIGHT: '#354973',
    BLUE_DARK: '#233049',

    ACCENT_BLUE: '#004dc5',
    ACCENT_BLUE_LIGHT: '#1b6ce9',
    ACCENT_CYAN: '#39c0f7',
    ACCENT_RED: '#f20b27',
    ACCENT_ORANGE: '#e89000',

    ERROR: '#eb5668',

    GREY_1: '#f9f9f9',
    GREY_2: '#e5e5e5',
    GREY_3: '#e2e2e2',
    GREY_4: '#dbdbdb',
    GREY_5: '#bbbbbb',
    GREY_6: '#9b9b9b',
    GREY_7: '#757575',
    GREY_8: '#686868',
    GREY_9: '#4a4a4a',
    GREY_10: '#d6d6d6',
};

const theme = {
    fontFamily:
        '-apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif',

    fontSize: {
        headline: toRem(30),
        title: toRem(20),
        nav: toRem(16),
        snackbar: toRem(15),
        table: toRem(15),
        text: toRem(14),
    },

    lineHeight: {
        headline: toRem(36),
        title: toRem(24),
        button: toRem(20),
        nav: toRem(19),
        snackbar: toRem(20),
        table: toRem(20),
        text: toRem(16),
    },

    fontWeight: {
        bold: 700,
        semibold: 600,
        medium: 500,
        regular: 400,
    },

    spacing: {
        small: toRem(14),
        medium: toRem(24),
        big: toRem(48),
    },

    borderRadius: {
        main: '4px',
        big: '8px',
    },
};

export default theme;
