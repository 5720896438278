import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import {
    generateCampaignId,
    ifProp,
    removeOdid,
} from '../../../helpers/general';
import appMessages from '../../App.messages';
import Headline from '../../atoms/Headline/Headline';
import Link from '../../atoms/Link/Link';
import Loading from '../../atoms/Loading/Loading';
import Title from '../../atoms/Title/Title';
import CopyButton from '../../molecules/CopyButton/CopyButton';
import Default from '../../templates/Default/Default';
import withShowLinkContainer from '../ShowLink/ShowLink.container';
import messages from './ShowLink.messages';

const Container = styled.div`
    padding: 42px 128px 55px;

    ${ifProp(
        'grey',
        css`
            background: #f9f9f9;
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
        `
    )};
`;

const StyledHeadline = styled(Headline)`
    margin-bottom: 1em;
`;

const StyledTitle = styled(Title)`
    margin-bottom: 1em;
`;

const StyledCopyButton = styled(CopyButton)`
    margin-bottom: 2em;
`;

const ValueDisplay = styled.p`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    grid-gap: 0.2em;
    justify-items: start;

    & + & {
        margin-top: 1.5em;
    }
`;

const Field = ({ field, url }) => (
    <React.Fragment>
        <ValueDisplay>
            <strong>{field.title}</strong>
            {url[field.key]}
        </ValueDisplay>
        {field.nestedFields.length
            ? field.nestedFields.map(f => (
                  <Field field={f} url={url} key={`Field ${f.key}`} />
              ))
            : ''}
    </React.Fragment>
);

const ShowLink = ({ url, loading, categorisedSchema }) => {
    if ((!url && loading) || !categorisedSchema) {
        return (
            <Default isCentered>
                <Loading />
            </Default>
        );
    }

    return (
        <Default hasNoPadding>
            <Container>
                <StyledHeadline>
                    <FormattedMessage
                        {...messages.headline}
                        values={{ id: generateCampaignId(url.createdAt) }}
                    />
                </StyledHeadline>

                <StyledCopyButton copy={url.shortenedUrl || url.url} />

                <ValueDisplay>
                    <strong>
                        <FormattedMessage {...appMessages.urlLabel} />
                    </strong>

                    <Link as="a" href={removeOdid(url.url)}>
                        {removeOdid(url.url)}
                    </Link>
                </ValueDisplay>
            </Container>

            {categorisedSchema.map((category, index) => (
                <Container
                    grey={index % 2 === 0}
                    key={`Field Container ${category.name}`}
                >
                    <StyledTitle>{category.name}</StyledTitle>

                    {category.fields.map(f => (
                        <Field field={f} url={url} key={`Field ${f.key}`} />
                    ))}
                </Container>
            ))}
        </Default>
    );
};

export { ShowLink };

export default withShowLinkContainer(ShowLink);
