import React from 'react';
import { compose } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import * as auth from '../../../apollo-client/modules/auth';
import { handleLogin } from '../../../apollo-client/util';
import SnackbarContext from '../../../context/SnackbarContext';
import { makeHoC } from '../../../helpers/general';

class LoginContainer extends React.Component {
    static contextType = SnackbarContext;

    // the get user query is cache-only, so we can disregard loading and error state here
    get isLoggedIn() {
        return (
            this.props.getUser.loginData &&
            this.props.getUser.loginData.accessToken
        );
    }

    login = (username, password) =>
        new Promise((resolve, reject) => {
            this.props
                .getToken({
                    variables: {
                        username,
                        password,
                    },
                    update: (_, { data }) => {
                        handleLogin(data);
                        resolve();
                    },
                })
                .catch(err => {
                    if (err.networkError && err.networkError.result) {
                        this.context.add(err.networkError.result.error);
                    }

                    reject();
                });
        });

    componentWillUnmount = () => {
        this.context.reset();
    };

    render = () => {
        if (this.isLoggedIn) {
            return <Redirect to="/" />;
        }

        const { Component } = this.props;

        return <Component doLogin={this.login} />;
    };
}

export default makeHoC(
    compose(auth.getUserQuery, auth.getTokenMutation)(LoginContainer)
);
