import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { color, fromTheme } from '../../../helpers/theme';

const StyledTitle = styled.h2`
    color: ${color('text', 'secondary')};
    font-size: ${fromTheme('fontSize', 'title')};
    line-height: ${fromTheme('lineHeight', 'title')};
    font-weight: ${fromTheme('fontWeight', 'medium')};
`;

const Title = ({ children, tag, ...props }) => (
    <StyledTitle as={tag} {...props}>
        {children}
    </StyledTitle>
);

Title.propTypes = {
    /** Title text or child element(s) */
    children: PropTypes.node.isRequired,

    /** HTML tag to use */
    tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span']),
};

Title.defaultProps = {
    tag: 'h2',
};

export default Title;
