import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import Button from '../../atoms/Button/Button';
import Headline from '../../atoms/Headline/Headline';
import BoxBackground from '../../templates/BoxBackground/BoxBackground';
import messages from './Error.messages';

const StyledHeadline = styled(Headline)`
    margin-bottom: 12px;
`;

const Text = styled.p`
    max-width: 256px;
    margin-bottom: 21px;
`;

const ButtonContainer = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    grid-gap: 15px;
    justify-items: center;
`;

const Error = ({ error, resetApp }) => (
    <BoxBackground>
        <StyledHeadline>
            <FormattedMessage {...messages.headline} />
        </StyledHeadline>

        <Text>
            <FormattedMessage {...messages.text} values={{ error }} />
        </Text>

        <ButtonContainer>
            <Button onClick={resetApp}>
                <FormattedMessage {...messages.btnText} />
            </Button>
        </ButtonContainer>
    </BoxBackground>
);

export default Error;
