import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ifProp } from '../../../helpers/general';
import { color, fromTheme } from '../../../helpers/theme';

const Container = styled.p`
    font-size: ${fromTheme('fontSize', 'snackbar')};
    line-height: ${fromTheme('lineHeight', 'snackbar')};
    padding: 20px 27px;
    border-radius: ${fromTheme('borderRadius', 'main')};

    ${ifProp(
        'variant',
        'error',
        css`
            background: ${color('error', 'main')};
            color: ${color('error', 'contrast')};
        `
    )};

    ${ifProp(
        'variant',
        'info',
        css`
            background: ${color('info', 'main')};
            color: ${color('info', 'contrast')};
        `
    )};

    ${({ onClick }) =>
        onClick &&
        css`
            &:hover {
                cursor: pointer;
            }
        `};
`;

const SNACKBAR_VARIANTS = ['error', 'info'];

const Snackbar = ({ children, onClose, ...props }) => (
    <Container onClick={onClose} {...props}>
        {children}
    </Container>
);

Snackbar.propTypes = {
    /** Snackbar content */
    children: PropTypes.node.isRequired,

    /** Handler to close the snackbar */
    onClose: PropTypes.func,

    /** Type of snackbar */
    variant: PropTypes.oneOf(SNACKBAR_VARIANTS),
};

Snackbar.defaultProps = {
    variant: SNACKBAR_VARIANTS[0],
};

export default Snackbar;
