import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import Snackbar from '../components/atoms/Snackbar/Snackbar';
import SnackbarContainer from '../components/templates/SnackbarContainer/SnackbarContainer';
import { addToArray, removeFromArrayByKey } from '../helpers/state';

const SnackbarContext = React.createContext();
const Consumer = SnackbarContext.Consumer;
class SnackbarProvider extends React.PureComponent {
    static propTypes = {
        maxMessages: PropTypes.number,
    };

    static defaultProps = {
        maxMessages: Infinity,
    };

    state = {
        snackbars: [],
    };

    addSnackbar = (message, type) => {
        const { maxMessages } = this.props;

        const id = uniqueId();

        const snackbar = { id, message, type };

        this.setState(addToArray('snackbars', snackbar, maxMessages));

        return () => this.removeSnackbar(id);
    };

    addTempSnackbar = (message, type, timeout = 3000) => {
        const removeFn = this.addSnackbar(message, type);
        setTimeout(removeFn, timeout);
    };

    removeSnackbar = id => {
        this.setState(removeFromArrayByKey('snackbars', 'id', id));
    };

    removeAllSnackbars = () => {
        this.setState({ snackbars: [] });
    };

    render = () => {
        const { children } = this.props;
        const { snackbars } = this.state;

        return (
            <SnackbarContext.Provider
                value={{
                    add: this.addSnackbar,
                    addTemp: this.addTempSnackbar,
                    reset: this.removeAllSnackbars,
                }}
            >
                {children}

                {snackbars.length ? (
                    <SnackbarContainer>
                        {snackbars.map(snackbar => (
                            <Snackbar
                                key={`Snackbar ${snackbar.id}`}
                                variant={snackbar.type}
                                onClose={() => this.removeSnackbar(snackbar.id)}
                            >
                                {snackbar.message}
                            </Snackbar>
                        ))}
                    </SnackbarContainer>
                ) : null}
            </SnackbarContext.Provider>
        );
    };
}

const SnackbarProducer = ({ message }) => {
    const snackbars = useContext(SnackbarContext);

    // Add snackbar, and remove on cleanup
    useEffect(() => snackbars.add(message), [message]);

    // This does not render anything itself
    return null;
};

export default SnackbarContext;

export { SnackbarProducer as Producer, SnackbarProvider as Provider, Consumer };
