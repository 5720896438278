export const addToArray =
    (key, item, maxItems = Infinity) =>
    state => ({
        [key]: [...state[key], item].slice(-maxItems),
    });

export const removeFromArray = (key, index) => state => ({
    [key]: [...state[key].slice(0, index), ...state[key].slice(index + 1)],
});

export const removeFromArrayByKey = (stateKey, key, value) => state => ({
    [stateKey]: state[stateKey].filter(item => item[key] !== value),
});
