import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { ifProp } from '../../../helpers/general';
import { color } from '../../../helpers/theme';
import NavLink, { TRANSITION_DURATION } from '../../molecules/NavLink/NavLink';
import messages from './MainNav.messages';

const Nav = styled.nav`
    background: ${color('nav', 'main')};
    padding: 40px 30px;
    height: 100%;
    width: 245px;
    transition: all ${TRANSITION_DURATION};

    ${ifProp(
        'isCollapsed',
        css`
            width: 95px;
        `
    )};
`;

const StyledNavLink = styled(NavLink)`
    padding-bottom: 25px;
    border-bottom: 1px solid ${color('nav', 'contrast')};
    box-sizing: content-box;
    margin-bottom: 30px;
    /* display: inline-flex; */
`;

const useHover = () => {
    const [isHovered, setHovered] = useState(false);

    const bindHoverEvents = {
        onMouseEnter: () => setHovered(true),
        onMouseLeave: () => setHovered(false),
    };

    return [isHovered, bindHoverEvents];
};

const MainNav = ({ history, ...props }) => {
    const [isHovered, bindHoverEvents] = useHover();

    const hasSeenFullMainNav = useMemo(
        () => !!localStorage.getItem('hasSeenFullMainNav'),
        [history.location.pathname]
    );

    if (!hasSeenFullMainNav) {
        localStorage.setItem('hasSeenFullMainNav', true);
    }

    const shouldShow = !hasSeenFullMainNav || isHovered;

    return (
        <Nav isCollapsed={!shouldShow} {...bindHoverEvents} {...props}>
            <StyledNavLink exact to="/" isCollapsed={!shouldShow}>
                <FormattedMessage {...messages.itemLinkList} />
            </StyledNavLink>
        </Nav>
    );
};

export default withRouter(MainNav);
