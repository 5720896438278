import React from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { getUserQuery } from '../../../apollo-client/modules/auth';
import { handleLogout } from '../../../apollo-client/util';
import { makeHoC } from '../../../helpers/general';

class DefaultContainer extends React.Component {
    // the get user query is cache-only, so we can disregard loading and error state here
    get isLoggedIn() {
        return (
            !!this.props.getUser.loginData &&
            !!this.props.getUser.loginData.accessToken
        );
    }

    logout = async () => {
        if (!this.isLoggedIn) {
            return;
        }

        // reset apollo client and stuff
        await handleLogout();

        // try to navigate to a protected page which will refetch the queries
        this.props.history.push('/');
    };

    render = () => {
        const { getUser, history, Component, ...layoutProps } = this.props;

        return (
            <Component
                isLoggedIn={this.isLoggedIn}
                doLogout={this.logout}
                {...layoutProps}
            />
        );
    };
}

export default makeHoC(compose(withRouter, getUserQuery)(DefaultContainer));
