import { DEFAULT_LOCALE } from '../config';

export const defaults = {
    locale: localStorage.getItem('locale') || DEFAULT_LOCALE,
};

export const resolvers = {
    Mutation: {
        locale: (_, { locale }, { cache }) => {
            localStorage.setItem('locale', locale);
            cache.writeData({ data: { locale } });
            return null;
        },
    },
};
