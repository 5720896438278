import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { filterProps } from '../../../helpers/general';
import Button, { buttonStyles } from '../Button/Button';

// Since styled-components does not filter out custom props and Link won't know
// what to do with them and then React will complain, do it manually!
const LinkButton = styled(
    filterProps(Link, ['isSmall', 'isGhost', 'isLoading'])
)`
    ${buttonStyles};
`;

LinkButton.propTypes = {
    ...Button.propTypes,

    /** Link target */
    to: PropTypes.string.isRequired,
};

export default LinkButton;
