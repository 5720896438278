import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ifProp } from '../../../helpers/general';
import { color, fromTheme } from '../../../helpers/theme';

const arrowSize = {
    width: 16,
    height: 6,
    get scale() {
        return this.width / 2 / this.height;
    },
    get innerHeight() {
        return this.height - 1;
    },
};

const Container = styled.div`
    background: ${color('tooltip', 'bg')};
    border: 1px solid ${color('tooltip', 'border')};
    border-radius: ${fromTheme('borderRadius', 'main')};
    filter: drop-shadow(-10px 9px 21px ${color('tooltip', 'shadow')});
    display: inline-flex;
    color: ${color('tooltip', 'color')};
    font-size: ${fromTheme('fontSize', 'text')};
    line-height: ${fromTheme('lineHeight', 'text')};
    padding: 11px 29px;
    position: relative;
    max-width: 350px;
    text-align: center;
    hyphens: auto;

    ${ifProp(
        'isDark',
        css`
            background: ${color('tooltip', 'color')};
            color: ${color('tooltip', 'bg')};
            border-color: ${color('tooltip', 'color')};
        `
    )};

    ${({ arrowPosition, isDark, theme }) => {
        // Use the arrow position (top, right, bottom or left) to pick
        // the attributes used in the CSS code below.
        // E.g. an arrow position of "top" will cause the pseudo elements to be positioned
        // bottom: 100%; left: 50%;
        const primaryPositionAttribute = {
            top: 'bottom',
            bottom: 'top',
            right: 'left',
            left: 'right',
        }[arrowPosition];

        const secondaryPositionAttribute = {
            top: 'left',
            bottom: 'left',
            right: 'top',
            left: 'top',
        }[arrowPosition];

        const scaleAxis = {
            top: 'X',
            bottom: 'X',
            right: 'Y',
            left: 'Y',
        }[arrowPosition];

        const bgColor = color('tooltip', isDark ? 'color' : 'bg')({ theme });
        const borderColor = color(
            'tooltip',
            isDark ? 'color' : 'border'
        )({
            theme,
        });

        return css`
            &::after,
            &::before {
                ${primaryPositionAttribute}: 100%;
                ${secondaryPositionAttribute}: 50%;
                border: solid transparent;
                content: '';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                transform: scale${scaleAxis}(${arrowSize.scale});
            }

            &::after {
                border-color: transparent;
                border-${primaryPositionAttribute}-color: ${bgColor};
                border-width: ${arrowSize.innerHeight}px;
                margin-${secondaryPositionAttribute}: -${arrowSize.innerHeight}px;
            }

            &::before {
                border-color: transparent;
                border-${primaryPositionAttribute}-color: ${borderColor};
                border-width: ${arrowSize.height}px;
                margin-${secondaryPositionAttribute}: -${arrowSize.height}px;
            }`;
    }};
`;

const Tooltip = ({ children, ...props }) => (
    <Container {...props}>{children}</Container>
);

Tooltip.propTypes = {
    /** Tooltip text */
    children: PropTypes.string.isRequired,

    /** Position of the arrow on the tooltip */
    arrowPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),

    /** Whether to render the dark version of the tooltip */
    isDark: PropTypes.bool,
};

Tooltip.defaultProps = {
    arrowPosition: 'bottom',
};

export default Tooltip;
