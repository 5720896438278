import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components/macro';
import Button from '../../atoms/Button/Button';
import Headline from '../../atoms/Headline/Headline';
import FormField from '../../molecules/FormField/FormField';
import messages from './LoginForm.messages';

const Form = styled.form`
    text-align: center;
    width: 100%;
`;

const StyledHeadline = styled(Headline)`
    margin-bottom: 42px;
`;

const StyledFormField = styled(FormField)`
    margin-bottom: 30px;
`;

const StyledButton = styled(Button)`
    margin-top: 10px;
    margin-bottom: 30px;
`;

/* istanbul ignore next */
const preventSubmit = e => e.preventDefault();

const LoginForm = ({
    values,
    onSubmit,
    onFieldChange,
    onFieldBlur,
    intl,
    isLoading,
}) => (
    <Form onSubmit={isLoading ? preventSubmit : onSubmit}>
        <StyledHeadline regular>
            <FormattedHTMLMessage {...messages.headline} />
        </StyledHeadline>

        <StyledFormField
            value={values.username}
            onChange={onFieldChange}
            onBlur={onFieldBlur}
            label={intl.formatMessage(messages.labelUsername)}
            isLabelHidden
            isGrey
        />

        <StyledFormField
            value={values.password}
            onChange={onFieldChange}
            onBlur={onFieldBlur}
            label={intl.formatMessage(messages.labelPassword)}
            isLabelHidden
            type="password"
            isGrey
        />

        <StyledButton isLoading={isLoading} type="submit">
            <FormattedMessage {...messages.btnText} />
        </StyledButton>

        <p>
            <FormattedMessage {...messages.forgotPasswordText} />
        </p>
    </Form>
);

LoginForm.propTypes = {
    /** Form submit handler */
    onSubmit: PropTypes.func.isRequired,

    /** Whether to display the form in a loading state, disabling submit */
    isLoading: PropTypes.bool,

    /** Values of the form fields */
    values: PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string,
    }),

    /** Field change handler */
    onFieldChange: PropTypes.func,

    /** Field blur handler */
    onFieldBlur: PropTypes.func,
};

LoginForm.defaultProps = {
    values: {},
    onFieldChange: null,
    onFieldBlur: null,
};

export default injectIntl(LoginForm);
