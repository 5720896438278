import React from 'react';
import { ApolloProvider, Query } from 'react-apollo';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import client from '../apollo-client';
import { GET_LOCALE_QUERY } from '../apollo-client/modules/app';
import { getUserQuery } from '../apollo-client/modules/auth';
import { hydrateCache } from '../apollo-client/util';
import { DEFAULT_THEME, MAX_SNACKBAR_MSGS, Router } from '../config';
import { Provider as SnackbarProvider } from '../context/SnackbarContext';
import I18nProvider from '../i18n/components/I18nProvider';
import routes from '../routes';
import themes from '../themes';
import GlobalStyle from './GlobalStyle';

const AuthenticatedRoute = getUserQuery(
    ({ component: Component, getUser: { loginData }, ...rest }) => (
        <Route
            render={props =>
                loginData && !!loginData.accessToken ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
            {...rest}
        />
    )
);

const AppWrapper = ({ children }) => (
    <ApolloProvider client={client}>
        <Query query={GET_LOCALE_QUERY}>
            {({ data: { locale } }) => (
                <I18nProvider locale={locale}>
                    <ThemeProvider theme={themes[DEFAULT_THEME]}>
                        <React.Fragment>
                            <GlobalStyle />

                            <SnackbarProvider maxMessages={MAX_SNACKBAR_MSGS}>
                                {children}
                            </SnackbarProvider>
                        </React.Fragment>
                    </ThemeProvider>
                </I18nProvider>
            )}
        </Query>
    </ApolloProvider>
);

class App extends React.PureComponent {
    state = {
        cacheLoaded: false,
    };

    componentDidMount = async () => {
        await hydrateCache();

        // If hydrateCache fails we won't have a cache but we're
        // still done setting it up, so we can render the App.
        this.setState({
            cacheLoaded: true,
        });
    };

    render = () => {
        return this.state.cacheLoaded ? (
            <AppWrapper>
                <Router>
                    <Switch>
                        {Object.keys(routes).map(key => {
                            const route = routes[key];

                            const RouteComponent = route.public
                                ? Route
                                : AuthenticatedRoute;

                            return (
                                <RouteComponent
                                    key={`Route ${key}`}
                                    exact={!!route.path}
                                    path={route.path}
                                    component={route.component}
                                />
                            );
                        })}
                    </Switch>
                </Router>
            </AppWrapper>
        ) : null;
    };
}

export default App;
