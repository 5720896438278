import styled from 'styled-components/macro';

const TwoColumns = styled.div`
    display: grid;
    grid-gap: 21px;
    grid-template-columns: 639px 192px;
    align-items: end;
`;

export default TwoColumns;
