import styled from 'styled-components/macro';

const SnackbarContainer = styled.div`
    display: grid;
    grid-gap: 21px;
    grid-template-rows: 1fr;
    position: fixed;
    bottom: 21px;
    width: 100vw;
    max-width: 720px;
    left: 0;
    right: 0;
    margin: auto;
`;

export default SnackbarContainer;
