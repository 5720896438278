import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { filterProps, ifProp } from '../../../helpers/general';
import { ReactComponent as Svg } from './assets/logo.svg';

const Logo = styled(filterProps(Svg, ['isSmall']))`
    display: inline-block;
    width: 103px;
    height: 115px;
    filter: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.08));

    ${ifProp(
        'isSmall',
        css`
            width: 35px;
            height: 40px;
            filter: none;
        `
    )};
`;

Logo.propTypes = {
    /** Whether the logo should be rendered in a smaller size */
    isSmall: PropTypes.bool,
};

Logo.defaultProps = {
    isSmall: false,
};

export default Logo;
