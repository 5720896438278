import memoize from 'lodash/memoize';
import React from 'react';
import { Query } from 'react-apollo';
import { GET_ALL_URIS_QUERY } from '../../../apollo-client/modules/uris';
import { Producer as SnackbarProducer } from '../../../context/SnackbarContext';
import { makeHoC } from '../../../helpers/general';
import { normaliseUrlParams } from '../../../helpers/schema';

export const SORT_DIRECTION = {
    ASC: 'asc',
    DESC: 'desc',
};

const DEFAULT_PAGE = 1;

class HomeContainer extends React.Component {
    normaliseUrlParams = memoize(normaliseUrlParams('uris'));

    state = {
        sortBy: 'created_at',
        sortDirection: SORT_DIRECTION.DESC,
        page: DEFAULT_PAGE,
    };

    sortBy = column => {
        this.setState(state => {
            if (state.sortBy === column) {
                return {
                    sortDirection:
                        state.sortDirection === SORT_DIRECTION.ASC
                            ? SORT_DIRECTION.DESC
                            : SORT_DIRECTION.ASC,
                };
            }

            return {
                sortBy: column,
                sortDirection: SORT_DIRECTION.ASC,
            };
        });
    };

    goBack = () => {
        this.setState({ page: this.state.page - 1 });
    };

    changePage = page => {
        this.setState({ page });
    };

    render = () => {
        const { Component } = this.props;

        return (
            <Query
                query={GET_ALL_URIS_QUERY}
                variables={{
                    sortBy: this.state.sortBy,
                    sortDirection: this.state.sortDirection,
                    page: this.state.page,
                }}
            >
                {({ data, error, loading }) => {
                    const normalisedData = this.normaliseUrlParams(data);

                    return (
                        <React.Fragment>
                            {error && (
                                <SnackbarProducer message="Failed to fetch data. You might be seeing cached data." />
                            )}

                            <Component
                                loading={loading}
                                data={normalisedData}
                                sortedBy={this.state.sortBy}
                                sortDirection={this.state.sortDirection}
                                sortBy={this.sortBy}
                                changePage={this.changePage}
                                goBack={this.goBack}
                                page={this.state.page}
                            />
                        </React.Fragment>
                    );
                }}
            </Query>
        );
    };
}

export default makeHoC(HomeContainer);
