import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { ifProp } from '../../../helpers/general';
import { color, fromTheme } from '../../../helpers/theme';

const StyledHeadline = styled.h1`
    color: ${color('text', 'secondary')};
    font-size: ${fromTheme('fontSize', 'headline')};
    line-height: ${fromTheme('lineHeight', 'headline')};
    font-weight: ${fromTheme('fontWeight', 'semibold')};

    ${ifProp(
        'isRegular',
        css`
            font-weight: ${fromTheme('fontWeight', 'regular')};
        `
    )};
`;

const Headline = ({ children, tag, ...props }) => (
    <StyledHeadline as={tag} {...props}>
        {children}
    </StyledHeadline>
);

Headline.propTypes = {
    /** Headline text or child element(s) */
    children: PropTypes.node.isRequired,

    /** HTML tag to use */
    tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span']),

    /** Whether to use the regular font-weight instead of the semi bold */
    isRegular: PropTypes.bool,
};

Headline.defaultProps = {
    tag: 'h1',
};

export default Headline;
