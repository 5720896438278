import PropTypes from 'prop-types';
import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { filterProps, ifProp } from '../../../helpers/general';
import { color, fromTheme } from '../../../helpers/theme';
import Icon from '../../atoms/Icon/Icon';

export const TRANSITION_DURATION = '.3s';

const StyledLink = styled(filterProps(Link, ['isCollapsed']))`
    display: flex;
    height: 20px;
    flex-direction: row;
    align-items: flex-end;
    text-align: left;
    color: ${color('nav', 'contrast')};
    text-decoration: none;
    font-weight: ${fromTheme('fontWeight', 'bold')};
    letter-spacing: 0.5px;
    font-size: ${fromTheme('fontSize', 'nav')};
    line-height: ${fromTheme('lineHeight', 'nav')};
    white-space: nowrap;
    transition: text-align ${TRANSITION_DURATION} ease ${TRANSITION_DURATION};

    &:hover,
    &.active {
        color: ${color('nav', 'active')};
    }

    ${ifProp(
        'isCollapsed',
        css`
            text-align: center;
            transition: text-align ${TRANSITION_DURATION} ease
                ${TRANSITION_DURATION};
        `
    )};
`;

const StyledIcon = styled(filterProps(Icon, ['noMargin']))`
    margin-right: 16px;
    margin-left: 0px;

    /* correct visual imbalance */
    transform: translateY(-1px);
    transition: all ${TRANSITION_DURATION};

    ${ifProp(
        'noMargin',
        css`
            margin-right: 8px;
            margin-left: 8px;
            transition: all ${TRANSITION_DURATION};
        `
    )};
`;

const TextWrapper = styled.span`
    max-width: 0;
    transition: none;
    overflow: hidden;
    opacity: 0;

    ${ifProp(
        'isVisible',
        css`
            max-width: 245px;
            transition: max-width ${TRANSITION_DURATION} ease
                ${TRANSITION_DURATION};
            opacity: 1;
        `
    )};
`;

const NavLink = ({ children, isCollapsed, ...props }) => {
    return (
        <StyledLink isCollapsed={isCollapsed} {...props}>
            <StyledIcon icon="menuLink" noMargin={isCollapsed} />
            <TextWrapper isVisible={!isCollapsed}>{children}</TextWrapper>
        </StyledLink>
    );
};

NavLink.propTypes = {
    /** Link text */
    children: PropTypes.node.isRequired,

    /** Whether the link is collapsed */
    isCollapsed: PropTypes.bool,
};

NavLink.defaultProps = {
    isCollapsed: false,
};

export default NavLink;
