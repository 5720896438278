import React from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { DEFAULT_LOCALE, DEFAULT_THEME } from '../config';
import I18nProvider from '../i18n/components/I18nProvider';
import themes from '../themes';
import GlobalStyle from './GlobalStyle';
import Error from './pages/Error/Error';

class ErrorBoundary extends React.PureComponent {
    state = {
        hasError: false,
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch = error => {
        this.props.errorHandler.report(error);
    };

    resetAfterError = () => {
        // hard reload + reset
        window.location = '/';
    };

    render = () => {
        if (this.state.hasError) {
            return (
                <I18nProvider locale={DEFAULT_LOCALE}>
                    <ThemeProvider theme={themes[DEFAULT_THEME]}>
                        <React.Fragment>
                            <GlobalStyle />
                            <Error resetApp={this.resetAfterError} />
                        </React.Fragment>
                    </ThemeProvider>
                </I18nProvider>
            );
        }

        return this.props.children;
    };
}

export default ErrorBoundary;
